import React, { useState, useEffect } from "react";
import { Stage, Layer, Rect } from "react-konva";
import moment from "moment";
import { SketchPicker } from 'react-color';
import "../../stylesheets/floor_plan.scss"
import HomeIcon from "../../../assets/images/home-icon.png"
import PaperSize from "../../images/floor_plan_icons/paper_size.png"
import Rectangle from "./Rectangle";
import Circle from "./Circle";
import Line from "./Line";
import ImageFP from "./Image";
import NatalChartGrid from "./NatalChartGrid";
import NatalChartPie from "./NatalChartPie";
import AnnualMonthlyChartGrid from "./AnnualMonthlyChartGrid";
import AnnualMonthlyChartPie from "./AnnualMonthlyChartPie";
import StaticChartGrid from "./StaticChartGrid";
import StaticChartPie from "./StaticChartPie";
import MountainChart from "./MountainChart";
import { DropImage } from "./DropImage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Text from "./Text";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NatalIcon from "../../images/floor_plan_icons/natal_fs_icon.png";
import StaticChartIcon from "../../images/floor_plan_icons/static_chart_icon.png"
import MountainIcon from "../../images/floor_plan_icons/24_mountains_icon.png"

var HISTORY = []
var POSITION = 0

const saveHistory = (history) => {
    var remove = (HISTORY.length - 1) - POSITION;
    HISTORY = HISTORY.slice(0, HISTORY.length - remove);
    HISTORY.push(history.slice(0));
    POSITION = HISTORY.length - 1;
}

const revertHistory = () => {
    return HISTORY[POSITION]
}

function Canvas() {
    const useLocalStorage = (keyName, defaultValue) => {
        const [storedValue, setStoredValue] = React.useState(() => {
            try {
                const value = window.localStorage.getItem(keyName);

                if (value) {
                    return JSON.parse(value);
                } else {
                    window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                    return defaultValue;
                }
            } catch (err) {
                return defaultValue;
            }
        });

        const setValue = newValue => {
            try {
                window.localStorage.setItem(keyName, JSON.stringify(newValue));
            } catch (err) {}
            setStoredValue(newValue);
        };
        
        return [storedValue, setValue];
    };

    const [canvasObjects, setCanvasObjects] = useLocalStorage('canvasObjects', []);
    const [selectedId, selectShape] = useState(null);
    const [shapes, setShapes] = useLocalStorage("shapes", []);
    const [images, setImages] = useLocalStorage("images", []);
    const stageEl = React.createRef();
    const layerEl = React.createRef();
    const containerCanvas = React.createRef();

    const fillFont = "black";
    const text = "Double clicks to edit";
    const [newTextObj, setNewTextObj] = useState({
        textEditVisible: false,
        textStyle: false,
        textXTextArea: 0,
        textYTextArea: 0,
        fill: fillFont,
        text: text,
        draggable: true,
        fontSize: 28,
        width: 250,
        x: 100,
        y: 100,
        width: 250,
        height: 150,
        fontStyle: "normal",
        type: "text",
        id: 0
    })

    const [textColorOpen, setTextColorOpen] = useState(false);
    const toggleTextColor = () => (
        setTextColorOpen(previousState => !previousState),
        setStrokeColorOpen(false),
        setFillColorOpen(false)
    );
    const textColorPicker = (e, selectedId) => {
        const newColor = {
            hex: e.hex,
            rgba: "rgba(" + e.rgb.r + ", " + e.rgb.g + ", " + e.rgb.b + ", " + e.rgb.a + ")"
        };
        const updatedText = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedText[index].fill = newColor.rgba;
        setCanvasObjects(updatedText);
    };

    const [fillColorOpen, setFillColorOpen] = useState(false);
    const toggleFillColor = () => (
        setFillColorOpen(previousState => !previousState),
        setStrokeColorOpen(false),
        setTextColorOpen(false)
    );
    const [strokeColorOpen, setStrokeColorOpen] = useState(false);
    const toggleStrokeColor = () => (
        setStrokeColorOpen(previousState => !previousState),
        setFillColorOpen(false),
        setTextColorOpen(false)
    );

    const fillColors = {rgba: "rgba(255, 255, 255, 0)"};
    const fillRectColor = (e, selectedId) => {
        const newColor = {
            hex: e.hex,
            rgba: "rgba(" + e.rgb.r + ", " + e.rgb.g + ", " + e.rgb.b + ", " + e.rgb.a + ")"
        };
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].fill = newColor.rgba;
        setCanvasObjects(updatedRect);
    };

    const strokeColors = {rgba: "rgba(0, 0, 0, 1)"};
    const strokeRectColor = (e, selectedId) => {
        const newColor = {
            hex: e.hex,
            rgba: "rgba(" + e.rgb.r + ", " + e.rgb.g + ", " + e.rgb.b + ", " + e.rgb.a + ")"
        };
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].stroke = newColor.rgba;
        setCanvasObjects(updatedRect);
    };

    const [companyHeader, setCompanyHeader] = useLocalStorage("companyHeader", "Lifeguide Consultancy");
    const [formChart, setFormChart] = useState(0);
    const [chartType, setChartType] = useState("");

    const [display_model, setDisplayModel] = useState(0);
    const [period_selected, setPeriodSelected] = useState(1);
    const [sequence_selected, setSequenceSelected] = useState("S1");
    const [set_calendar, setCalendarSelected] = useState(new Date());
    const [periods, setPeriods] = useState(null);
    const [directions, setDirections] = useState([]);
    const [canvasWidth, setCanvasWidth] = useLocalStorage("canvasWidth", 882);
    const [canvasHeight, setCanvasHeight] = useLocalStorage("canvasHeight", 635);
    const [canvasZoom, setCanvasZoom] = useState(1);
    const [marginLeft, setMarginLeft] = useLocalStorage("marginLeft", 80);
    const [marginTop, setMarginTop] = useLocalStorage("marginTop", 30);
    const [potraitA3Active, setPotraitA3Active] = useLocalStorage("potraitA3Active", "");
    const [potraitA4Active, setPotraitA4Active] = useLocalStorage("potraitA4Active", "");
    const [landscapeA3Active, setLandscapeA3Active] = useLocalStorage("landscapeA3Active", "");
    const [landscapeA4Active, setLandscapeA4Active] = useLocalStorage("landscapeA4Active", "border-3");
    const [orientationFormat, setOrientationFormat] = useLocalStorage("orientationFormat", "landscape");
    const [natalEnabled, setNatalEnabled] = useState(true);
    const toggleNatal = () => setNatalEnabled(previousState => !previousState);
    const [annualEnabled, setAnnualEnabled] = useState(false);
    const toggleAnnual = () => setAnnualEnabled(previousState => !previousState);
    const [monthlyEnabled, setMonthlyEnabled] = useState(false);
    const toggleMonthly = () => setMonthlyEnabled(previousState => !previousState);
    const [sideBar, setSideBar] = useState(true);
    const [textEdit, setTextEdit] = useState(false);
    const [workspaceTab, setWorkspaceTab] = useState("show active");
    const [optionsTab, setOptionsTab] = useState("");
    const activeTab = {
        'workspace': workspaceTab,
        'options': optionsTab
    }
    const toggleSideBar = () => setSideBar(
        previousState => !previousState,
        setWorkspaceTab("show active"),
        setOptionsTab("")
    );

    const [southEastEnabled, setSouthEastEnabled] = useState(true);
    const toggleSouthEast = (selectedId) => {
        setSouthEastEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["SE"] = southEastEnabled;
        setCanvasObjects(updatedRect);
    }

    const [southEnabled, setSouthEnabled] = useState(true);
    const toggleSouth = (selectedId) => {
        setSouthEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["S"] = southEnabled;
        setCanvasObjects(updatedRect);
    }

    const [southWestEnabled, setSouthWestEnabled] = useState(true);
    const toggleSouthWest = (selectedId) => {
        setSouthWestEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["SW"] = southWestEnabled;
        setCanvasObjects(updatedRect);
    }

    const [westEnabled, setWestEnabled] = useState(true);
    const toggleWest = (selectedId) => {
        setWestEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["W"] = westEnabled;
        setCanvasObjects(updatedRect);
    }

    const [northWestEnabled, setNorthWestEnabled] = useState(true);
    const toggleNorthWest = (selectedId) => {
        setNorthWestEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["NW"] = northWestEnabled;
        setCanvasObjects(updatedRect);
    }

    const [northEnabled, setNorthEnabled] = useState(true);
    const toggleNorth = (selectedId) => {
        setNorthEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["N"] = northEnabled;
        setCanvasObjects(updatedRect);
    }

    const [northEastEnabled, setNorthEastEnabled] = useState(true);
    const toggleNorthEast = (selectedId) => {
        setNorthEastEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["NE"] = northEastEnabled;
        setCanvasObjects(updatedRect);
    }

    const [eastEnabled, setEastEnabled] = useState(true);
    const toggleEast = (selectedId) => {
        setEastEnabled(previousState => !previousState);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].baguaSelected["E"] = eastEnabled;
        setCanvasObjects(updatedRect);
    }

    const [descEnabled, setDescEnabled] = useState(0);
    const toggleDescEnable = (value, selectedId) => {
        setDescEnabled(value);
        const updatedRect = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedRect[index].descEnabled = value;
        setCanvasObjects(updatedRect);
    }

    function setPageSize(cssPageSize) {
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${cssPageSize}; margin: -1mm 0mm 0mm 0mm; padding-top: 10mm; height: 210mm; width: 210mm;}`;
        style.id = 'page-orientation';
        document.head.appendChild(style);
    }

    useEffect(() => {
        fetch(`/api/v1/index`)
            .then((response) => response.json())
            .then((data) => { 
                setPeriods(data.periodsData),
                setDirections(data.directionsData)
            });

        stageEl.current.findOne(".transparentBackground").hide();
        setPageSize(orientationFormat);
        return () => {
            const child = document.getElementById('page-orientation');
            child.parentNode.removeChild(child);
        };
    }, [orientationFormat]);

    const addNewText = () => {
        newTextObj.id = `text${canvasObjects.length + 1}`;
        const textElements = canvasObjects.concat([newTextObj]);
        setCanvasObjects(textElements)
        setNewTextObj({ ...newTextObj });
        const shs = shapes.concat([`text${canvasObjects.length + 1}`]);
        setShapes(shs);
        saveHistory(textElements);
        selectShape(newTextObj.id);
    };

    const handleTextClick = (index) => {
        const updatedText = [...canvasObjects];
        updatedText[index].textStyle = true;
        setCanvasObjects(updatedText);
        saveHistory(updatedText);
    };

    const handleTextDblClick = (e, index) => {
        const absPos = e.target.getAbsolutePosition();
        const stageBox = stageEl.current.container().getBoundingClientRect();
        for (let i; i < canvasObjects.length; i++) {
            canvasObjects[i].textEditVisible = false;
        }
        const updatedText = [...canvasObjects];
        updatedText[index].textEditVisible = true;
        updatedText[index].textXTextArea = stageBox.left + absPos.x + containerCanvas.current.scrollLeft;
        updatedText[index].textYTextArea = stageBox.bottom + absPos.y - stageBox.height + 40 + containerCanvas.current.scrollTop;
        setCanvasObjects(updatedText);
        saveHistory(updatedText);
    };

    const handleTextEdit = (e, index) => {
        const updatedText = [...canvasObjects];
        updatedText[index].text = e.target.value;
        setCanvasObjects(updatedText);
        saveHistory(updatedText);
    };

    const changeFontSize = (e, selectedId) => {
        const updatedText = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedText[index].fontSize = e.target.value;
        setCanvasObjects(updatedText);
        saveHistory(updatedText);
    };

    const changeFontStyle = (e, selectedId) => {
        const updatedText = [...canvasObjects];
        index = canvasObjects.findIndex(t => t.id === selectedId);
        updatedText[index].fontStyle = e.target.value;
        setCanvasObjects(updatedText);
        saveHistory(updatedText);
    };

    const addRectangle = () => {
        const rect = {
            x: (canvasWidth / 3),
            y: (canvasHeight / 3),
            width: 100,
            height: 100,
            rotation: 0,
            fill: (fillColors.rgba),
            stroke: (strokeColors.rgba),
            type: "rectangle",
            id: `rect${canvasObjects.length + 1}`
        };
        const rects = canvasObjects.concat([rect]);
        setCanvasObjects(rects);
        const shs = shapes.concat([`rect${canvasObjects.length + 1}`]);
        setShapes(shs);
        saveHistory(rects);
        selectShape(rect.id);
    };

    const addCircle = () => {
        const circ = {
            x: (canvasWidth / 3),
            y: (canvasHeight / 3),
            width: 100,
            height: 100,
            fill: (fillColors.rgba),
            stroke: (strokeColors.rgba),
            type: "circle",
            id: `circ${canvasObjects.length + 1}`
        };
        const circs = canvasObjects.concat([circ]);
        setCanvasObjects(circs);
        const shs = shapes.concat([`circ${canvasObjects.length + 1}`]);
        setShapes(shs);
        saveHistory(circs);
        selectShape(circ.id);
    };

    const addMountainChart = () => {
        const chart = {
            x: (canvasWidth / 5),
            y: (canvasHeight / 7),
            width: 500,
            height: 500,
            type: "mountainChart",
            id: `chart${canvasObjects.length + 1}`
        };
        const charts = canvasObjects.concat([chart]);
        setCanvasObjects(charts);
        const shs = shapes.concat([`chart${canvasObjects.length + 1}`]);
        setShapes(shs);
        saveHistory(charts);
        selectShape(chart.id);
        window.$('#formModal').modal('hide');
    };

    const addLineShape = () => {
        const line = {
            x: (canvasWidth / 3),
            y: (canvasHeight / 3),
            width: 100,
            height: 10,
            fill: ("rgba(255, 0, 0, 1)"),
            stroke: ("rgba(255, 255, 255, 0)"),
            type: "line",
            id: `line${canvasObjects.length + 1}`
        };
        const lines = canvasObjects.concat([line]);
        setCanvasObjects(lines);
        const shs = shapes.concat([`line${canvasObjects.length + 1}`]);
        setShapes(shs);
        saveHistory(lines);
        selectShape(line.id);
    };

    const addNewImage = (newImage) => {
        const img = {
            x: 100,
            type: 'image',
            width: newImage.width,
            height: newImage.height,
            id: `img${images.length + 1}`
        };
        const keyImage = {
            image: newImage.src,
            id: `img${images.length + 1}`
        };
        const imges = images.concat([keyImage]);
        setImages(imges);
        const imgs = canvasObjects.concat([img]);
        setCanvasObjects(imgs);
        const shs = shapes.concat([`img${canvasObjects.length + 1}`]);
        setShapes(shs);
        saveHistory(imgs);
        selectShape(img.id);
    };

    const loadImage = (base64) => {
        const image = new window.Image();
        image.src = `data:image/png;base64,${base64}`;
        image.onload = () => {
            if (image.width > 1000) {
                var max = 500;
                var ratio = (image.width > image.height ? (image.width/ max) : (image.height / max));
                image.width = image.width / ratio
                image.height = image.height / ratio
            }
            addNewImage(image);
        };
    };

    const handleOptions = () => {
        if (sideBar !== true) {
            setSideBar(true);
        }
        setWorkspaceTab("");
        setOptionsTab("show active");
    };

    const handleChart = (chartType) => {
        if (sideBar !== true) {
            setSideBar(true);
        }
        setWorkspaceTab("");
        setOptionsTab("show active");
        setChartType(chartType);
    };

    const undo = () => {
        POSITION = POSITION === 0 ? POSITION : POSITION - 1
        const history = revertHistory();
        setCanvasObjects(history.slice(0));
    };

    const redo = () => {
        POSITION = POSITION < HISTORY.length - 1 ? POSITION + 1 : POSITION
        const history = revertHistory();
        setCanvasObjects(history.slice(0));
    };

    const handleKeyDown = (ev) => {
        if (ev.code === "Delete" && textEdit != true || ev.code === "Backspace" && textEdit != true) {
            const updatedObject = [...canvasObjects];
            if (updatedObject.length > 0) {
                for (let i = 0; i < updatedObject.length; i++) {
                    if (selectedId === updatedObject[i].id) {
                        updatedObject.splice(i, 1);
                    }
                }
                saveHistory(updatedObject);
                setCanvasObjects(updatedObject);
            }
        }

		if(ev.code === "KeyB" && ev.ctrlKey){
			if (sideBar) {
                setSideBar(false);
            } else {
                setSideBar(true);
            }
        }
    }

    const deleteObject = () => {
        const updatedObject = [...canvasObjects];
        if (updatedObject.length > 0) {
            for (let i = 0; i < updatedObject.length; i++) {
                if (selectedId === updatedObject[i].id) {
                    updatedObject.splice(i, 1);
                }
            }
            saveHistory(updatedObject);
            setCanvasObjects(updatedObject);
        }
    }

    const moveForward = () => {
        const updatedObject = [...canvasObjects];
        const indexObject = canvasObjects.findIndex(c => c.id === selectedId);
        updatedObject.push(updatedObject.splice(indexObject + 1, 0, updatedObject.splice(indexObject, 1)[0]));
        setCanvasObjects(updatedObject);
        saveHistory(updatedObject);
    }

    const moveBackward = () => {
        const updatedObject = [...canvasObjects];
        const indexObject = canvasObjects.findIndex(c => c.id === selectedId);
        updatedObject.push(updatedObject.splice(indexObject - 1, 0, updatedObject.splice(indexObject, 1)[0]));
        setCanvasObjects(updatedObject);
        saveHistory(updatedObject);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(`/api/v1/calculate?period=${period_selected}&facing_direction=${sequence_selected}&date=${moment(set_calendar).format("DD MMMM YYYY")}`)
        .then((response) => response.json())
        .then((fp_data) => {
            if (natalEnabled == true || (natalEnabled != true && annualEnabled != true && monthlyEnabled != true)) {
                if (display_model == 0) {
                    const chart = {
                        x: (100),
                        y: (100),
                        width: 500,
                        height: 500,
                        directions: ['SE', 'S', 'SW', 'W', 'NW', 'N', 'NE', 'E'],
                        data: fp_data,
                        annualOptions: annualEnabled,
                        monthlyOptions: monthlyEnabled,
                        descEnabled: 0,
                        sideHover: "",
                        descTarget: "",
                        baguaSelected: {'SE' : false, 'S' : false, 'SW' : false, 'W' : false, 'NW' : false, 'N' : false, 'NE' : false, 'E' : false},
                        type: "natalChartGrid",
                        id: `chart${canvasObjects.length + 1}`
                    };
                    const charts = canvasObjects.concat([chart]);
                    setCanvasObjects(charts);
                    const shs = shapes.concat([`chart${canvasObjects.length + 1}`]);
                    setShapes(shs);
                    saveHistory(charts);
                    selectShape(chart.id);
                } else {
                    const chart = { 
                        x: (100),
                        y: (100),
                        width: 500,
                        height: 500,
                        directions: ['SE', 'S', 'SW', 'W', 'NW', 'N', 'NE', 'E'],
                        data: fp_data,
                        annualOptions: annualEnabled,
                        monthlyOptions: monthlyEnabled,
                        descEnabled: 0,
                        sideHover: "",
                        descTarget: "",
                        baguaSelected: {'SE' : false, 'S' : false, 'SW' : false, 'W' : false, 'NW' : false, 'N' : false, 'NE' : false, 'E' : false},
                        type: "natalChartPie",
                        id: `chart${canvasObjects.length + 1}`
                    };
                    const charts = canvasObjects.concat([chart]);
                    setCanvasObjects(charts);
                    const shs = shapes.concat([`chart${canvasObjects.length + 1}`]);
                    setShapes(shs);
                    saveHistory(charts);
                    selectShape(chart.id);
                }
            }
            else if (annualEnabled == true || monthlyEnabled == true) {
                if (display_model == 0) {
                    const chart = {
                        x: (100),
                        y: (100),
                        width: 500,
                        height: 500,
                        directions: ['SE', 'S', 'SW', 'W', 'NW', 'N', 'NE', 'E'],
                        data: fp_data,
                        annualOptions: annualEnabled,
                        monthlyOptions: monthlyEnabled,
                        baguaSelected: {'SE' : false, 'S' : false, 'SW' : false, 'W' : false, 'NW' : false, 'N' : false, 'NE' : false, 'E' : false},
                        type: "annualMonthlyChartGrid",
                        id: `chart${canvasObjects.length + 1}`
                    };
                    const charts = canvasObjects.concat([chart]);
                    setCanvasObjects(charts);
                    const shs = shapes.concat([`chart${canvasObjects.length + 1}`]);
                    setShapes(shs);
                    saveHistory(charts);
                    selectShape(chart.id);
                } else {
                    const chart = { 
                        x: (100),
                        y: (100),
                        width: 500,
                        height: 500,
                        directions: ['SE', 'S', 'SW', 'W', 'NW', 'N', 'NE', 'E'],
                        data: fp_data,
                        annualOptions: annualEnabled,
                        monthlyOptions: monthlyEnabled,
                        baguaSelected: {'SE' : false, 'S' : false, 'SW' : false, 'W' : false, 'NW' : false, 'N' : false, 'NE' : false, 'E' : false},
                        type: "annualMonthlyChartPie",
                        id: `chart${canvasObjects.length + 1}`
                    };
                    const charts = canvasObjects.concat([chart]);
                    setCanvasObjects(charts);
                    const shs = shapes.concat([`chart${canvasObjects.length + 1}`]);
                    setShapes(shs);
                    saveHistory(charts);
                    selectShape(chart.id);
                }
            }
        });
        window.$('#formModal').modal('hide');
    }

    const buttonFormSubmit = (event) => {
        event.preventDefault();
        fetch(`/api/v1/calculate?period=1&facing_direction=S1&date=01+October+2000`)
        .then((response) => response.json())
        .then((fp_data) => {
            if (display_model == 0) {
                const chart = {
                    x: (100),
                    y: (100),
                    width: 500,
                    height: 500,
                    directions: ['SE', 'S', 'SW', 'W', 'NW', 'N', 'NE', 'E'],
                    data: fp_data,
                    baguaSelected: {'SE' : false, 'S' : false, 'SW' : false, 'W' : false, 'NW' : false, 'N' : false, 'NE' : false, 'E' : false},
                    type: "staticChartGrid",
                    id: `chart${canvasObjects.length + 1}`
                };
                const charts = canvasObjects.concat([chart]);
                setCanvasObjects(charts);
                const shs = shapes.concat([`chart${canvasObjects.length + 1}`]);
                setShapes(shs);
                saveHistory(charts);
                selectShape(chart.id);
            } else {
                const chart = { 
                    x: (100),
                    y: (100),
                    width: 500,
                    height: 500,
                    directions: ['SE', 'S', 'SW', 'W', 'NW', 'N', 'NE', 'E'],
                    data: fp_data,
                    baguaSelected: {'SE' : false, 'S' : false, 'SW' : false, 'W' : false, 'NW' : false, 'N' : false, 'NE' : false, 'E' : false},
                    type: "staticChartPie",
                    id: `chart${canvasObjects.length + 1}`
                };
                const charts = canvasObjects.concat([chart]);
                setCanvasObjects(charts);
                const shs = shapes.concat([`chart${canvasObjects.length + 1}`]);
                setShapes(shs);
                saveHistory(charts);
                selectShape(chart.id);
            }
        });
        window.$('#formModal').modal('hide');
    }

    function downloadURI(uri, name) {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleExportClick = (e) => {
        if (e.target.value == 0) {
            stageEl.current.findOne(".transparentBackground").show();
            const uri = stageEl.current.toDataURL();
            downloadURI(uri, `Floor Plan - ${companyHeader}.jpg`);
        } else {
            stageEl.current.findOne(".transparentBackground").hide();
            const uri = stageEl.current.toDataURL();
            downloadURI(uri, `Floor Plan - ${companyHeader}.png`);
        }
    }

    const printCanvas = () => {
        setCanvasZoom(1);
        setSideBar(false);
        setTimeout(() => {
            var win1 = window.open();
            win1.document.write('<div style="margin-left:' + marginLeft + 'px; margin-top:' + marginTop +'px;"><img src="' + stageEl.current.toDataURL() + '"></div>');
            setTimeout(() => {
                win1.print();
            }, 100);
        }, 200);
    }

    const canvasZoomOut = () => {
        if (!(canvasZoom > 3.25)) {
            setCanvasZoom(canvasZoom + 0.25);
        } else {
            false
        }
    }

    const canvasZoomIn = () => {
        if (landscapeA3Active != "" && !(canvasZoom < 1)) {
            setCanvasZoom(canvasZoom - 0.25);
        } else if (landscapeA3Active == "" && !(canvasZoom < 0.75)) {
            setCanvasZoom(canvasZoom - 0.25);
        } else {
            false
        }
    }

    const resetCanvas = () => { 
        localStorage.clear();
        location.reload();
        HISTORY = [];
        POSITION = 0;
    }

    const potraitA3 = () => {
        setCanvasZoom(1);
        setPotraitA3Active("border-3");
        setPotraitA4Active("");
        setLandscapeA3Active("");
        setLandscapeA4Active("");
        setCanvasWidth(921);
        setCanvasHeight(1279);
        setOrientationFormat("potrait");
        setMarginLeft(60);
        setMarginTop(90);
        setSideBar(false);
    }

    const potraitA4 = () => {
        setCanvasZoom(1);
        setPotraitA3Active("");
        setPotraitA4Active("border-3");
        setLandscapeA3Active("");
        setLandscapeA4Active("");
        setCanvasWidth(635);
        setCanvasHeight(882);
        setOrientationFormat("potrait");
        setMarginLeft(30);
        setMarginTop(50);
        setSideBar(false);
    }

    const landscapeA3 = () => {
        setCanvasZoom(1);
        setPotraitA3Active("");
        setPotraitA4Active("");
        setLandscapeA3Active("border-3");
        setLandscapeA4Active("");
        setCanvasWidth(1279);
        setCanvasHeight(921);
        setOrientationFormat("landscape");
        setMarginLeft(110);
        setMarginTop(50);
        setSideBar(false);
    }

    const landscapeA4 = () => {
        setCanvasZoom(1);
        setPotraitA3Active("");
        setPotraitA4Active("");
        setLandscapeA3Active("");
        setLandscapeA4Active("border-3");
        setCanvasWidth(882);
        setCanvasHeight(635);
        setOrientationFormat("landscape");
        setMarginLeft(80);
        setMarginTop(30);
        setSideBar(false);
    }

    const natalFlyingStarForm = () => {
        setFormChart(1);
    };

    const staticChartForm = () => {
        setFormChart(2);
    };

    return (
        <div className="containerCanvas" ref={containerCanvas} onKeyDown={handleKeyDown} tabIndex="-1">
            <div className="container-fluid p-0 bg-white breadcrumb-area">
                <div className="container p-0 breadcrumb-nav">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb" className="navbar navbar-expand-lg">
                                <ol className="breadcrumb bg-white">
                                    <li className="breadcrumb-item"><a href="/dashboard"><img src={HomeIcon} alt="HomeIcon"/></a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Floor Plan</li>
                                </ol>
                                <div className="ml-auto">
                                    <ul className="nav nav-sidebar p-1">
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Undo</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={undo}><i className="fa-lg fas fa-undo"></i></button>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Redo</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={redo}><i className="fa-lg fas fa-redo"></i></button>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Clear</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={resetCanvas}><i className="fa-lg fas fa-sync"></i></button>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <div className="dropdown">
                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Add Object</Tooltip>}>
                                                    <button type="button" className="btn btn-sm btn-light border btn-tools" id="dropdownEditButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-lg fas fa-edit"></i></button>
                                                </OverlayTrigger>
                                                <div className="dropdown-menu" aria-labelledby="dropdownEditButton">
                                                    <div className="dropdown-item" onClick={addNewText}>
                                                        <button type="button" className="btn btn-sm btn-light border btn-tool"><span className="icon-t">T</span></button>
                                                        <span className="ml-2">Text</span>
                                                    </div>
                                                    <div className="dropdown-item" onClick={addRectangle}>
                                                        <button type="button" className="btn btn-sm btn-light border btn-tool"><i className="fa-lg fas fa-stop"></i></button>
                                                        <span className="ml-2">Rectangle</span>
                                                    </div>
                                                    <div className="dropdown-item" onClick={addCircle}>
                                                        <button type="button" className="btn btn-sm btn-light border btn-tool"><i className="fa-lg far fa-circle"></i></button>
                                                        <span className="ml-2">Circle</span>
                                                    </div>
                                                    <div className="dropdown-item" onClick={addLineShape}>
                                                        <button type="button" className="btn btn-sm btn-light border btn-tool"><i className="fa-lg fas fa-minus"></i></button>
                                                        <span className="ml-2">Line</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Zoom Out</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={canvasZoomOut}><i className="fa-lg fas fa-search-minus"></i></button>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Zoom In</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={canvasZoomIn}><i className="fa-lg fas fa-search-plus"></i></button>
                                            </OverlayTrigger>
                                        </li>
                                        { selectedId ?
                                            <React.Fragment>
                                                <span className="tools-separator">|</span>
                                                <li>
                                                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Move Forward</Tooltip>}>
                                                        <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={moveBackward}><i className="fa-lg fas fa-sort-amount-down"></i></button>
                                                    </OverlayTrigger>
                                                </li>
                                                <li>
                                                    <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Move Backward</Tooltip>}>
                                                        <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={moveForward}><i className="fa-lg fas fa-sort-amount-up"></i></button>
                                                    </OverlayTrigger>
                                                </li>
                                            </React.Fragment>
                                            : false
                                        }
                                        <span className="tools-separator">|</span>
                                        <li>
                                            <div className="dropdown">
                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Download</Tooltip>}>
                                                    <button type="button" className="btn btn-sm btn-light border btn-tools" id="dropdownDownloadButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-lg fas fa-download"></i></button>
                                                </OverlayTrigger>
                                                <div className="dropdown-menu" aria-labelledby="dropdownDownloadButton">
                                                    <button className="dropdown-item" value="0" onClick={handleExportClick}>JPG</button>
                                                    <button className="dropdown-item" value="1" onClick={handleExportClick}>PNG</button>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Print</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={printCanvas}><i className="fa-lg fas fa-print"></i></button>
                                            </OverlayTrigger>
                                        </li>
                                        <span className="tools-separator">|</span>
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Chart</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" data-toggle="modal" data-target="#formModal" onClick={() => setFormChart(0)}><i className="fa-lg fas fa-vector-square"></i></button>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <DropImage getImage={base64 => loadImage(base64)}>
                                                <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Image</Tooltip>}>
                                                    <button type="button" className="btn btn-sm btn-danger border btn-tools"><i className="fa-lg fas fa-upload"></i></button>
                                                </OverlayTrigger>
                                            </DropImage>
                                        </li>
                                        <li>
                                            <OverlayTrigger placement={"bottom"} overlay={<Tooltip id="tooltip-disabled">Side Bar (Ctrl + B)</Tooltip>}>
                                                <button type="button" className="btn btn-sm btn-light border btn-tools" onClick={toggleSideBar} value={true}><span className="px-2">☰</span></button>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="formModal" tabIndex="-1" role="dialog" aria-labelledby="formModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="formModalLabel">Create Chart</h5>
                        </div>
                        <div className="modal-body p-0">
                            <div className="bazi-modal">
                                { formChart === 0 ?
                                    <div className="container">
                                        <div className="row text-center">
                                            <div className="col-12 col-md-4 filtering" style={{cursor: "pointer"}} onClick={natalFlyingStarForm}>
                                                <img src={NatalIcon} alt="Natal Flying Star Icon" width="150" className="mt-3"/>
                                                <h6 className="font-weight-bold mt-3">Flying Star</h6>
                                            </div>
                                            <div className="col-12 col-md-4 filtering" style={{cursor: "pointer"}} onClick={staticChartForm}>
                                                <img src={StaticChartIcon} alt="Static Chart Icon" width="150" className="mt-3"/>
                                                <h6 className="font-weight-bold mt-3">Static Chart</h6>
                                            </div>
                                            <div className="col-12 col-md-4 filtering" style={{cursor: "pointer"}} onClick={addMountainChart}>
                                                <img src={MountainIcon} alt="24 Mountains Icon" width="150" className="mt-3"/>
                                                <h6 className="font-weight-bold mt-3">24 Mountain</h6>
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block border-top-0">
                                            <button type="button" className="btn btn-close-modal w-100" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </div>
                                : formChart === 1 ? 
                                    <form onSubmit={handleSubmit}>
                                        <div className="field mb-24">
                                            <div className="row mx-auto">
                                                <h6>Display Model</h6>
                                            </div>
                                            <div className="row mx-auto">
                                                <label className="radio-inline mr-4"><input
                                                    type="radio"
                                                    name="display_model"
                                                    value={0}
                                                    checked={display_model == 0}
                                                    onChange={(e) => setDisplayModel(e.target.value)}
                                                />&nbsp;&nbsp;9 Grids</label>
                                                <label className="radio-inline"><input
                                                    type="radio"
                                                    name="display_model"
                                                    value={1}
                                                    checked={display_model == 1}
                                                    onChange={(e) => setDisplayModel(e.target.value)}
                                                />&nbsp;&nbsp;Pie</label>
                                            </div>
                                        </div>
                                        <div className="field mb-24">
                                            <div className="row mx-auto">
                                                <h6>Period</h6>
                                            </div>
                                            <select className="form-control" onChange={(e) => setPeriodSelected(e.target.value)} value={period_selected}>
                                                {periods && periods.map((period, i) => (
                                                    <option key={i} value={period.period}>Period {period.period} ({period.year})</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="field mb-24">
                                            <div className="row mx-auto">
                                                <h6>Facing Direction</h6>
                                            </div>
                                            <select className="form-control" onChange={(e) => setSequenceSelected(e.target.value)} value={sequence_selected}>
                                                {directions && directions.map((direction, i) => (
                                                    <option key={i} value={direction.sequence}>({direction.sequence}) {direction.start_degree} - {direction.end_degree} ({direction.mountain_name})</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="field mb-24">
                                            <div className="row mx-auto">
                                                <h6>Date</h6>
                                            </div>
                                            <input
                                                type="hidden"
                                                name="calendar"
                                                value={set_calendar}
                                                onChange={(e) => setCalendarSelected(e.target.value)}
                                            />
                                            <div className='calendar-container'>
                                                <DatePicker 
                                                    selected={set_calendar}
                                                    onChange={(date) => setCalendarSelected(date)}
                                                    minDate={new Date("02-05-1900")}
                                                    maxDate={new Date("01-20-2051")}
                                                    dateFormat="dd MMMM yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="row mx-auto">
                                                <h6 className="mb-0">Type</h6>
                                            </div>
                                            <div className="row mx-auto">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" name="option1" id="option1" onChange={toggleNatal} value={setNatalEnabled} checked={natalEnabled != false} />
                                                    <label className="form-check-label mt-3" htmlFor="option1">Natal Flying Star<span className="font-13 text-danger d-block">* Red Color</span></label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" name="option2" id="option2" onChange={toggleAnnual} value={setAnnualEnabled} checked={annualEnabled != false} />
                                                    <label className="form-check-label mt-3" htmlFor="option2">Annual Flying Star<span className="font-13 text-primary d-block">* Blue Color</span></label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" name="option3" id="option3" onChange={toggleMonthly} value={setMonthlyEnabled} checked={monthlyEnabled != false} />
                                                    <label className="form-check-label mt-3" htmlFor="option3">Monthly Flying Star<span className="font-13 text-success d-block">* Green Color</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block border-top-0">
                                            <div className="row">
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-close-modal w-100" onClick={() => setFormChart(0)}>Back</button>
                                                </div>
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-close-modal w-100" data-dismiss="modal">Cancel</button>
                                                </div>
                                                <div className="col-4">
                                                    <input type="submit" className="btn btn-submit-modal w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                : formChart === 2 ? 
                                    <form onSubmit={buttonFormSubmit}>
                                        <div className="field mb-24">
                                            <div className="row mx-auto">
                                                <h6>Display Model</h6>
                                            </div>
                                            <div className="row mx-auto">
                                                <label className="radio-inline mr-4"><input
                                                    type="radio"
                                                    name="display_model"
                                                    value={0}
                                                    checked={display_model == 0}
                                                    onChange={(e) => setDisplayModel(e.target.value)}
                                                />&nbsp;&nbsp;9 Grids</label>
                                                <label className="radio-inline"><input
                                                    type="radio"
                                                    name="display_model"
                                                    value={1}
                                                    checked={display_model == 1}
                                                    onChange={(e) => setDisplayModel(e.target.value)}
                                                />&nbsp;&nbsp;Pie</label>
                                            </div>
                                        </div>
                                        <div className="modal-footer d-block border-top-0">
                                            <div className="row">
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-close-modal w-100" onClick={() => setFormChart(0)}>Back</button>
                                                </div>
                                                <div className="col-4">
                                                    <button type="button" className="btn btn-close-modal w-100" data-dismiss="modal">Cancel</button>
                                                </div>
                                                <div className="col-4">
                                                    <input type="submit" className="btn btn-submit-modal w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                : false
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="wrapper">
                { sideBar ? 
                    <div id="sidebar-wrapper">
                        <div className="container sidebar-item">
                            <div className="container my-3">
                                <nav className="nav nav-pills nav-justified card-button">
                                    <a className={`nav-item nav-link ${activeTab['workspace']}`} id="nav-workspace-tab" data-toggle="tab" href="#nav-workspace" role="tab" aria-controls="nav-workspace" aria-selected="true">Workspace</a>
                                    <a className={`nav-item nav-link ${activeTab['options']}`} id="nav-options-tab" data-toggle="tab" href="#nav-options" role="tab" aria-controls="nav-options" aria-selected="false">Options</a>
                                </nav>
                            </div>
                            <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                <div className={`tab-pane fade show ${activeTab['workspace']}`} id="nav-workspace" role="tabpanel" aria-labelledby="nav-workspace-tab">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12">
                                                <div className={`card card-paper text-center ${potraitA4Active}`} style={{cursor: "pointer"}} onClick={potraitA4}>
                                                    <img
                                                        src={PaperSize}
                                                        className="card-img-top paper-size"
                                                        alt="HomeIcon"
                                                    />
                                                    <div className="card-body card-body-paper">
                                                        <span className="font-weight-bold">A4 (Potrait)</span><br/>
                                                        <span className="text-secondary"><small>21 x 29.7 cm</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12">
                                                <div className={`card card-paper text-center ${potraitA3Active}`} style={{cursor: "pointer"}} onClick={potraitA3}>
                                                    <img
                                                        src={PaperSize}
                                                        className="card-img-top paper-size"
                                                        alt="HomeIcon"
                                                    />
                                                    <div className="card-body card-body-paper">
                                                        <span className="font-weight-bold">A3 (Potrait)</span><br/>
                                                        <span className="text-secondary"><small>29.7 x 42 cm</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12">
                                                <div className={`card card-paper text-center ${landscapeA4Active}`} style={{cursor: "pointer"}} onClick={landscapeA4}>
                                                    <img
                                                        src={PaperSize}
                                                        className="card-img-top paper-size"
                                                        alt="HomeIcon"
                                                    />
                                                    <div className="card-body card-body-paper">
                                                        <span className="font-weight-bold">A4 (Landscape)</span><br/>
                                                        <span className="text-secondary"><small>29.7 x 21 cm</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 a3-paper">
                                                <div className={`card card-paper text-center ${landscapeA3Active}`} style={{cursor: "pointer"}} onClick={landscapeA3}>
                                                    <img
                                                        src={PaperSize}
                                                        className="card-img-top paper-size"
                                                        alt="HomeIcon"
                                                    />
                                                    <div className="card-body card-body-paper">
                                                        <span className="font-weight-bold">A3 (Landscape)</span><br/>
                                                        <span className="text-secondary"><small>42 x 29.7 cm</small></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade px-3 ${activeTab['options']}`} id="nav-options" role="tabpanel" aria-labelledby="nav-options-tab">
                                    <div>
                                        <span>Company Header</span>
                                        <input className="mt-2" type="text" onChange={e => setCompanyHeader(e.target.value)} value={companyHeader} />
                                    </div>
                                    {canvasObjects.map((item, index) => {
                                        if (index === canvasObjects.findIndex(r => r.id === selectedId) && canvasObjects[index].type === "rectangle") {
                                            return (
                                                <div className="container mb-24 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12 mb-24">
                                                            <button className="btn btn-danger" onClick={deleteObject}>Delete</button>
                                                        </div>
                                                        <div className="col-md-12 col-lg-4">
                                                            Fill <button className="btn btn-color-picker text-white" style={{ background: item.fill }} onClick={toggleFillColor}></button>
                                                        </div>
                                                        <div className="col-md-12 col-lg-4">
                                                            Stroke <button className="btn btn-color-picker text-white" style={{ background: item.stroke }} onClick={toggleStrokeColor}></button>
                                                        </div>
                                                        <div className="col-2">
                                                        </div>
                                                        <div className="col-10">
                                                            {fillColorOpen && <SketchPicker
                                                                    color={item.fill}
                                                                    onChange={(e) => fillRectColor(e, selectedId)}
                                                                    renderers={false}
                                                                    className="mt-3"
                                                                />
                                                            }
                                                            {strokeColorOpen && <SketchPicker
                                                                    color={item.stroke}
                                                                    onChange={(e) => strokeRectColor(e, selectedId)}
                                                                    renderers={false}
                                                                    className="mt-3"
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (index === canvasObjects.findIndex(r => r.id === selectedId) && canvasObjects[index].type === "circle") {
                                            return (
                                                <div className="container mb-24 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12 mb-24">
                                                            <button className="btn btn-danger" onClick={deleteObject}>Delete</button>
                                                        </div>
                                                        <div className="col-md-12 col-lg-4">
                                                            Fill <button className="btn btn-color-picker text-white" style={{ background: item.fill }} onClick={toggleFillColor}></button>
                                                        </div>
                                                        <div className="col-md-12 col-lg-4">
                                                            Stroke <button className="btn btn-color-picker text-white" style={{ background: item.stroke }} onClick={toggleStrokeColor}></button>
                                                        </div>
                                                        <div className="col-2">
                                                        </div>
                                                        <div className="col-10">
                                                            {fillColorOpen && <SketchPicker
                                                                    color={item.fill}
                                                                    onChange={(e) => fillRectColor(e, selectedId)}
                                                                    renderers={false}
                                                                    className="mt-3"
                                                                />
                                                            }
                                                            {strokeColorOpen && <SketchPicker
                                                                    color={item.stroke}
                                                                    onChange={(e) => strokeRectColor(e, selectedId)}
                                                                    renderers={false}
                                                                    className="mt-3"
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (index === canvasObjects.findIndex(r => r.id === selectedId) && canvasObjects[index].type === "line") {
                                            return (
                                                <div className="container mb-24 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-6 col-lg-6 mb-24">
                                                            <button className="btn btn-danger" onClick={deleteObject}>Delete</button>
                                                        </div>
                                                        <div className="col-md-6 col-lg-6">
                                                            Fill <button className="btn btn-color-picker text-white" style={{ background: item.fill }} onClick={toggleFillColor}></button>
                                                        </div>
                                                        <div className="col-10">
                                                            {fillColorOpen && <SketchPicker
                                                                    color={item.fill}
                                                                    onChange={(e) => fillRectColor(e, selectedId)}
                                                                    renderers={false}
                                                                    className="mt-3"
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (index === canvasObjects.findIndex(r => r.id === selectedId) && canvasObjects[index].type === "text") {
                                            return (
                                                <div className="container">
                                                    <div
                                                        className="row"
                                                        key={index}
                                                    >
                                                        <div className="col-3 mb-24 mt-3">
                                                            <button className="btn btn-danger" onClick={deleteObject}>Delete</button>
                                                        </div>
                                                        <div className="col-2 mb-24 mt-3">
                                                            <button className="btn btn-color-picker text-white" style={{ background: item.fill }} onClick={toggleTextColor}></button>
                                                        </div>
                                                        <div className="col-4 mb-24 mt-3">
                                                            <select className="form-control" onChange={(e) => changeFontStyle(e, selectedId)}>
                                                                <option value="normal" selected={item.fontStyle == "normal"}>Normal</option>
                                                                <option value="bold" selected={item.fontStyle == "bold"}>Bold</option>
                                                                <option value="italic" selected={item.fontStyle == "italic"}>Italic</option>
                                                                <option value="italic bold" selected={item.fontStyle == "italic bold"}>Italic Bold</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-3">
                                                            <select 
                                                                className="form-control mt-3" 
                                                                value={item.fontSize}
                                                                onChange={(e) => changeFontSize(e, selectedId)}
                                                            >
                                                                {[...new Array(100)].map(
                                                                    (i, idx) =>
                                                                    idx > 5 && (
                                                                        <option
                                                                            value={idx}
                                                                        >
                                                                            {idx}
                                                                        </option>
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="col-10">
                                                            {textColorOpen && <SketchPicker
                                                                    color={item.fill}
                                                                    onChange={(e) => textColorPicker(e, selectedId)}
                                                                    renderers={false}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (index === canvasObjects.findIndex(r => r.id === selectedId) && ["natalChartGrid", "natalChartPie", "annualMonthlyChartGrid", "annualMonthlyChartPie", "staticChartGrid", "staticChartPie"].includes(canvasObjects[index].type)) {
                                            const baguaSelected = canvasObjects[index].baguaSelected
                                            return (
                                                <div className="container">
                                                    <div
                                                        className="row"
                                                        key={index}
                                                    >
                                                        <div>
                                                            <button className="btn btn-danger mt-3" onClick={deleteObject}>Delete</button>
                                                            <div className="row m-auto">
                                                                <span className="my-3">Gua Display</span>
                                                            </div>
                                                            <div className="row">
                                                                <div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleSouth(selectedId)} checked={baguaSelected["S"]} /><span className="ml-2 text-color-gray">S (South)</span></label>
                                                                    </div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleSouthWest(selectedId)} checked={baguaSelected["SW"]} /><span className="ml-2 text-color-gray">SW (South West)</span></label>
                                                                    </div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleWest(selectedId)} checked={baguaSelected["W"]}/><span className="ml-2 text-color-gray">W (West)</span></label>
                                                                    </div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleNorthWest(selectedId)} checked={baguaSelected["NW"]} /><span className="ml-2 text-color-gray">NW (North West)</span></label>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleNorth(selectedId)} checked={baguaSelected["N"]} /><span className="ml-2 text-color-gray">N (North)</span></label>
                                                                    </div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleNorthEast(selectedId)} checked={baguaSelected["NE"]} /><span className="ml-2 text-color-gray">NE (North East)</span></label>
                                                                    </div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleEast(selectedId)} checked={baguaSelected["E"]} /><span className="ml-2 text-color-gray">E (East)</span></label>
                                                                    </div>
                                                                    <div className="checkbox ml-3">
                                                                        <label><input type="checkbox" onChange={() => toggleSouthEast(selectedId)} checked={baguaSelected["SE"]} /><span className="ml-2 text-color-gray">SE (South East)</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row m-auto">
                                                                {chartType == "natalChartGrid" || chartType == "natalChartPie" ? <span className="my-3">81 Combinations</span> : ""}
                                                            </div>
                                                            <div className="row m-auto">
                                                                <label className="radio-inline">{chartType == "natalChartGrid" || chartType == "natalChartPie" ? <input type="radio" name="optradio" checked={canvasObjects[index].descEnabled == 0} onClick={() => toggleDescEnable(0, selectedId)} /> : ""}{chartType == "natalChartGrid" || chartType == "natalChartPie" ? <span className="ml-2 mr-4 text-color-gray">None</span> : ""}</label>
                                                                <label className="radio-inline">{chartType == "natalChartGrid" || chartType == "natalChartPie" ? <input type="radio" name="optradio" checked={canvasObjects[index].descEnabled == 1} onClick={() => toggleDescEnable(1, selectedId)} /> : ""}{chartType == "natalChartGrid" || chartType == "natalChartPie" ? <span className="ml-2 mr-4 text-color-gray">Short</span> : ""}</label>
                                                                <label className="radio-inline">{chartType == "natalChartGrid" || chartType == "natalChartPie" ? <input type="radio" name="optradio" checked={canvasObjects[index].descEnabled == 2} onClick={() => toggleDescEnable(2, selectedId)} /> : ""}{chartType == "natalChartGrid" || chartType == "natalChartPie" ? <span className="ml-2 mr-4 text-color-gray">Long</span> : ""}</label>
                                                            </div>
                                                            <div className="row m-auto">
                                                                {canvasObjects[index].descEnabled != 0 ? <p className="font-13">{canvasObjects[index].descTarget}</p> : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (index === canvasObjects.findIndex(r => r.id === selectedId) && canvasObjects[index].type === "mountainChart") {
                                            return (
                                                <div className="container">
                                                    <div
                                                        className="row"
                                                        key={index}
                                                    >
                                                        <div>
                                                            <button className="btn btn-danger mt-3" onClick={deleteObject}>Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            false;
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""    
                }
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="mx-auto my-5">
                                <Stage
                                    scaleY={1 / canvasZoom}
                                    scaleX={1 / canvasZoom}
                                    width={canvasWidth / canvasZoom}
                                    height={canvasHeight / canvasZoom}
                                    ref={stageEl}
                                    onMouseDown={e => {
                                        const clickedOnEmpty = e.target === e.target.getStage();
                                        if (clickedOnEmpty) {
                                            selectShape(null);
                                            setSideBar(false);
                                            setTextColorOpen(false);
                                            setFillColorOpen(false);
                                            setStrokeColorOpen(false);
                                            setTextEdit(false);
                                            if (selectedId !== null) {
                                                index = canvasObjects.findIndex(t => t.id === selectedId);
                                                const updatedText = [...canvasObjects];
                                                updatedText[index].textEditVisible = false;
                                                updatedText[index].textStyle = false;
                                                setCanvasObjects(updatedText);
                                            } else {
                                                false;
                                            }
                                        }
                                    }}
                                    onTap={e => {
                                        const clickedOnEmpty = e.target === e.target.getStage();
                                        if (clickedOnEmpty) {
                                            selectShape(null);
                                            setSideBar(false);
                                            setTextColorOpen(false);
                                            setFillColorOpen(false);
                                            setStrokeColorOpen(false);
                                            if (selectedId !== null) {
                                                index = canvasObjects.findIndex(t => t.id === selectedId);
                                                const updatedText = [...canvasObjects];
                                                updatedText[index].textEditVisible = false;
                                                updatedText[index].textStyle = false;
                                                setCanvasObjects(updatedText);
                                            } else {
                                                false;
                                            }
                                        }
                                    }}
                                >
                                    <Layer
                                        ref={layerEl}
                                        className={"layer"}
                                    >
                                        <Rect
                                            name="transparentBackground"
                                            width={canvasWidth}
                                            height={canvasHeight}
                                            fill="white"
                                        />
                                        {canvasObjects.map((item, i) => {
                                            return (
                                                item.type === 'image' ?
                                                    <ImageFP
                                                        key={i}
                                                        imageUrl={images.find(t => t.id === item.id).image}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const imges = canvasObjects.slice();
                                                            imges[i] = newAttrs;
                                                            setCanvasObjects(imges);
                                                            saveHistory(imges);
                                                        }}
                                                    />
                                                : item.type === 'circle' ?
                                                    <Circle
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleOptions();
                                                        }}
                                                        onChange={newAttrs => {
                                                            const circs = canvasObjects.slice();
                                                            circs[i] = newAttrs;
                                                            setCanvasObjects(circs);
                                                            saveHistory(circs);
                                                        }}
                                                    />
                                                : item.type === 'rectangle' ?
                                                    <Rectangle
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleOptions();
                                                        }}
                                                        onChange={newAttrs => {
                                                            const rects = canvasObjects.slice();
                                                            rects[i] = newAttrs;
                                                            setCanvasObjects(rects);
                                                            saveHistory(rects);
                                                        }}
                                                    />
                                                : item.type === 'line'  ?
                                                    <Line
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleOptions();
                                                        }}
                                                        onChange={newAttrs => {
                                                            const lines = canvasObjects.slice();
                                                            lines[i] = newAttrs;
                                                            setCanvasObjects(lines);
                                                            saveHistory(lines);
                                                        }}
                                                    />
                                                : item.type === 'text' ?
                                                    <Text
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleTextClick(i);
                                                            handleOptions();
                                                        }}
                                                        handleTextDblClick={(e) => {
                                                            handleTextDblClick(e, i);
                                                            setTextEdit(true);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const textElements = canvasObjects.slice();
                                                            textElements[i] = newAttrs;
                                                            setCanvasObjects(textElements);
                                                            saveHistory(textElements);
                                                        }}
                                                    />
                                                :  item.type === 'natalChartGrid' ?
                                                    <NatalChartGrid
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleChart(item.type);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const charts = canvasObjects.slice();
                                                            charts[i] = newAttrs;
                                                            setCanvasObjects(charts);
                                                            saveHistory(charts);
                                                        }}
                                                        canvasZoomValue={canvasZoom}
                                                    />
                                                :  item.type === 'natalChartPie' ?
                                                    <NatalChartPie
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleChart(item.type);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const charts = canvasObjects.slice();
                                                            charts[i] = newAttrs;
                                                            setCanvasObjects(charts);
                                                            saveHistory(charts);
                                                        }}
                                                        canvasZoomValue={canvasZoom}
                                                    />
                                                :  item.type === 'annualMonthlyChartGrid' ?
                                                    <AnnualMonthlyChartGrid
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleChart(item.type);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const charts = canvasObjects.slice();
                                                            charts[i] = newAttrs;
                                                            setCanvasObjects(charts);
                                                            saveHistory(charts);
                                                        }}
                                                        canvasZoomValue={canvasZoom}
                                                    />
                                                :  item.type === 'annualMonthlyChartPie' ?
                                                    <AnnualMonthlyChartPie
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleChart(item.type);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const charts = canvasObjects.slice();
                                                            charts[i] = newAttrs;
                                                            setCanvasObjects(charts);
                                                            saveHistory(charts);
                                                        }}
                                                        canvasZoomValue={canvasZoom}
                                                    />
                                                :  item.type === 'staticChartGrid' ?
                                                    <StaticChartGrid
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleChart(item.type);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const charts = canvasObjects.slice();
                                                            charts[i] = newAttrs;
                                                            setCanvasObjects(charts);
                                                            saveHistory(charts);
                                                        }}
                                                        canvasZoomValue={canvasZoom}
                                                    />
                                                :  item.type === 'staticChartPie' ?
                                                    <StaticChartPie
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleChart(item.type);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const charts = canvasObjects.slice();
                                                            charts[i] = newAttrs;
                                                            setCanvasObjects(charts);
                                                            saveHistory(charts);
                                                        }}
                                                        canvasZoomValue={canvasZoom}
                                                    />
                                                :  item.type === 'mountainChart' ?
                                                    <MountainChart
                                                        key={i}
                                                        shapeProps={item}
                                                        isSelected={item.id === selectedId}
                                                        onSelect={() => {
                                                            selectShape(item.id);
                                                            handleChart(item.type);
                                                        }}
                                                        onChange={newAttrs => {
                                                            const charts = canvasObjects.slice();
                                                            charts[i] = newAttrs;
                                                            setCanvasObjects(charts);
                                                            saveHistory(charts);
                                                        }}
                                                    />
                                                : false
                                            );
                                        })}
                                    </Layer>
                                </Stage>
                            </div>
                            {canvasObjects.map((item, index) => {
                                return item.type === "text" ? (
                                    <textarea
                                        key={index}
                                        value={item.text}
                                        style={{
                                            display: item.textEditVisible ? "block" : "none",
                                            position: "absolute",
                                            top: item.textYTextArea - 40 + "px",
                                            left: item.textXTextArea + "px",
                                            width: item.width,
                                            height: item.height,
                                            fontSize: item.fontSize,
                                            color: item.fill,
                                            fontStyle: item.fontStyle,
                                            fontWeight: item.fontStyle
                                        }}
                                        onChange={e => handleTextEdit(e, index)}
                                    />
                                ) : (
                                    false
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Canvas;
