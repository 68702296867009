import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "inputHourFirst", "inputMinuteFirst", "buttonHourFirst", "buttonMinuteFirst"
    ]
    connect() {
        this.inputHourFirstTarget.disabled = this.buttonHourFirstTarget.checked
        this.inputMinuteFirstTarget.disabled = this.buttonMinuteFirstTarget.checked
        this.buttonMinuteFirstTarget.disabled = this.buttonHourFirstTarget.checked
    }

    unknownHourFirst() {
        if (this.buttonHourFirstTarget.checked) {
            this.inputHourFirstTarget.disabled = true
            this.inputMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.checked = true
        } else {
            this.inputHourFirstTarget.disabled = false
            this.inputMinuteFirstTarget.disabled = false
            this.buttonMinuteFirstTarget.disabled = false
            this.buttonMinuteFirstTarget.checked = false
        }
    }

    unknownMinuteFirst() {
        if (this.buttonMinuteFirstTarget.checked) {
            this.inputMinuteFirstTarget.disabled = true
        } else {
            this.inputMinuteFirstTarget.disabled = false
        }
    }
}