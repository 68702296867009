import { useState } from 'react';
import React from "react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function App() {
    const [startDate, setStartDate] = useState(new Date());

    return (
        <div className='calendar-container'>
            <DatePicker 
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                minDate={new Date("02-05-1900")}
                maxDate={new Date("01-20-2051")}
                dateFormat="dd MMMM yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </div>
    );
}

export default App;
