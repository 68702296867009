import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "inputHourFirst", "inputMinuteFirst", "buttonHourFirst", "buttonMinuteFirst", "inputMonth", "buttonMonth", "inputDay", "buttonDay", "inputHourComp", "buttonHourComp", "inputMinuteComp", "buttonMinuteComp" ]
    connect() {
        if (!this.buttonHourFirstTarget.checked) {
            this.inputHourFirstTarget.disabled = false
            this.inputMinuteFirstTarget.disabled = false
            this.buttonMinuteFirstTarget.disabled = false
        } else {
            this.inputHourFirstTarget.disabled = true
            this.inputMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.checked = true
        }

        if (!this.buttonMinuteFirstTarget.checked) {
            this.inputMinuteFirstTarget.disabled = false
        } else {
            this.inputMinuteFirstTarget.disabled = true
        }
        this.unknownMonth()
        this.unknownDay()
        this.unknownHourComp()
        this.unknownMinuteComp()
    }

    unknownHourFirst() {
        this.inputHourFirstTarget.disabled = this.buttonHourFirstTarget.checked
        this.inputMinuteFirstTarget.disabled = this.buttonHourFirstTarget.checked
        this.buttonMinuteFirstTarget.disabled = this.buttonHourFirstTarget.checked
        if (!this.buttonHourFirstTarget.checked) {
            this.buttonMinuteFirstTarget.checked = false
        } else {
            this.buttonMinuteFirstTarget.checked = true
        }
    }

    unknownMinuteFirst() {
        this.inputMinuteFirstTarget.disabled = this.buttonMinuteFirstTarget.checked
    }

    unknownMonth() {
        this.inputMonthTarget.disabled = this.buttonMonthTarget.checked

        this.inputDayTarget.disabled = this.buttonMonthTarget.checked
        this.buttonDayTarget.checked = this.buttonMonthTarget.checked
        this.buttonDayTarget.disabled = this.buttonMonthTarget.checked

        this.inputHourCompTarget.disabled = this.buttonMonthTarget.checked
        this.buttonHourCompTarget.checked = this.buttonMonthTarget.checked
        this.buttonHourCompTarget.disabled = this.buttonMonthTarget.checked

        this.inputMinuteCompTarget.disabled = this.buttonMonthTarget.checked
        this.buttonMinuteCompTarget.checked = this.buttonMonthTarget.checked
        this.buttonMinuteCompTarget.disabled = this.buttonMonthTarget.checked
    }

    unknownDay() {
        this.inputDayTarget.disabled = this.buttonDayTarget.checked

        this.inputHourCompTarget.disabled = this.buttonDayTarget.checked
        this.buttonHourCompTarget.checked = this.buttonDayTarget.checked
        this.buttonHourCompTarget.disabled = this.buttonDayTarget.checked

        this.inputMinuteCompTarget.disabled = this.buttonDayTarget.checked
        this.buttonMinuteCompTarget.checked = this.buttonDayTarget.checked
        this.buttonMinuteCompTarget.disabled = this.buttonDayTarget.checked
    }

    unknownHourComp() {
        this.inputHourCompTarget.disabled = this.buttonHourCompTarget.checked

        this.inputMinuteCompTarget.disabled = this.buttonHourCompTarget.checked
        this.buttonMinuteCompTarget.checked = this.buttonHourCompTarget.checked
        this.buttonMinuteCompTarget.disabled = this.buttonHourCompTarget.checked
    }

    unknownMinuteComp() {
        this.inputMinuteCompTarget.disabled = this.buttonMinuteCompTarget.checked
    }
}