import React from 'react';
import Calendar from "./Calendar"

export default class FloorPlanData extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            periods: [],
            directions: [],
            display_model: "",
            period: "",
            sequence: ""
        };
    }

    handleInputChange(event) {
        this.setState({
            display_model: event.target.display_model,
            additional: event.target.additional,
        });
    }

    componentDidMount() {
        fetch('/api/v1/floor_plan?period=1&facing_direction=S1&date=18+February+2022&display_model=0&annual=0&monthly=0').
        then((response) => response.json()).
        then((floor_plan) =>  this.setState({ 
            display_model: floor_plan[3],
            annual: floor_plan[4]
        }));
    }

    render() {
        const { periods } = this.state;
        const { directions } = this.state;
        return (
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create Chart</h5>
                    </div>
                    <div className="modal-body p-0">
                        <div className="bazi-modal">
                            <div className="field mb-24">
                                <div className="row mx-auto">
                                    <h6>Display Model</h6>
                                </div>
                                <div className="row mx-auto">
                                    <label className="radio-inline mr-4"><input
                                        type="radio"
                                        name="display_model"
                                        value="0"
                                        checked
                                        onChange={this.handleInputChange.bind(this)}
                                    />&nbsp;&nbsp;9 Grids</label>
                                    <label className="radio-inline"><input
                                        type="radio"
                                        name="display_model"
                                        value="1"
                                        onChange={this.handleInputChange.bind(this)}
                                    />&nbsp;&nbsp;Pie</label>
                                </div>
                            </div>
                            <div className="field mb-24">
                                <div className="row mx-auto">
                                    <h6>Period</h6>
                                </div>
                                <select className="form-control" value={this.state.period} onChange={this.handleInputChange.bind(this)}>
                                    {periods.map((period) => (
                                        <option value={period.period}>Period {period.period} ({period.year})</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field mb-24">
                                <div className="row mx-auto">
                                    <h6>Facing Direction</h6>
                                </div>
                                <select className="form-control" value={this.state.sequence} onChange={this.handleInputChange.bind(this)}>
                                    {directions.map((direction) => (
                                        <option value={direction.seqeunce}>({direction.sequence}) {direction.start_degree} - {direction.end_degree} ({direction.mountain_name})</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field mb-24">
                                <div className="row mx-auto">
                                    <h6>Date</h6>
                                </div>
                                <Calendar />
                            </div>
                            <div className="field">
                                <div className="row mx-auto">
                                    <h6>Type</h6>
                                </div>
                                <div className="row mx-auto">
                                    <label className="radio-inline mr-4"><input
                                        type="radio"
                                        name="type"
                                        value="0"
                                        checked
                                        onChange={this.handleInputChange.bind(this)}
                                    />&nbsp;&nbsp;Annual Flying Star</label>
                                    <label className="radio-inline"><input
                                        type="radio"
                                        name="type"
                                        value="1"
                                        onChange={this.handleInputChange.bind(this)}
                                    />&nbsp;&nbsp;Monthly Flying Star</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-block border-top-0">
                        <div className="row">
                            <div className="col-6">
                                <button type="button" className="btn btn-close-modal w-100" data-dismiss="modal">Cancel</button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-submit-modal w-100">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}