import React from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

const StaticChartGrid = ({ shapeProps, isSelected, onSelect, onChange, canvasZoomValue }) => {

function rotateRight(arr){
        let last = arr.pop();
        arr.unshift(last);
        return arr;
    }

    function rotateLeft(arr){
        let first = arr.shift();
        arr.push(first)
        return arr;
    }

    const directions = shapeProps.directions;
    const baguaSelected = shapeProps.baguaSelected;
    const guaSymbol = shapeProps.data['guaSymbol'];
    const guaName = shapeProps.data['guaName'];

    const baseSVG =
        `<svg height="300px" width="300px" x="0px" y="0px" viewbox="0 0 207 142" xmlns="http://www.w3.org/2000/svg" version="1.1" style="transform: translate(0,0)">
            <svg x="126" y="5">
                ${isSelected ? "<path fill-rule='evenodd' d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z'/><path d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z'/>" : ""}
            </svg>
            <svg x="158" y="5">
                ${isSelected ? "<path fill-rule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'/><path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z'/>" : ""}
            </svg>

            <text x="30" y="60" fill="red" font-size="18">${directions[0]}</text>
            <text x="75" y="100" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[0]] != false ? guaSymbol[directions[0]] : ""}</text>
            <text x="75" y="115" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[0]] != false ? guaName[directions[0]] : ""}</text>

            <text x="145" y="60" fill="red" font-size="18">${directions[1]}</text>
            <text x="135" y="100" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[1]] != false ? guaSymbol[directions[1]] : ""}</text>
            <text x="135" y="115" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[1]] != false ? guaName[directions[1]] : ""}</text>

            <text x="250" y="60" fill="red" font-size="18">${directions[2]}</text>
            <text x="200" y="100" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[2]] != false ? guaSymbol[directions[2]] : ""}</text>
            <text x="200" y="115" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[2]] != false ? guaName[directions[2]] : ""}</text>

            <text x="30" y="150" fill="red" font-size="18">${directions[7]}</text>
            <text x="75" y="150" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[7]] != false ? guaSymbol[directions[7]] : ""}</text>
            <text x="75" y="165" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[7]] != false ? guaName[directions[7]] : ""}</text>

            <text x="250" y="150" fill="red" font-size="18">${directions[3]}</text>
            <text x="200" y="150" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[3]] != false ? guaSymbol[directions[3]] : ""}</text>
            <text x="200" y="165" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[3]] != false ? guaName[directions[3]] : ""}</text>

            <text x="30" y="240" fill="red" font-size="18">${directions[6]}</text>
            <text x="75" y="205" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[6]] != false ? guaSymbol[directions[6]] : ""}</text>
            <text x="75" y="220" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[6]] != false ? guaName[directions[6]] : ""}</text>

            <text x="145" y="240" fill="red" font-size="18">${directions[5]}</text>
            <text x="135" y="205" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[5]] != false ? guaSymbol[directions[5]] : ""}</text>
            <text x="135" y="220" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[5]] != false ? guaName[directions[5]] : ""}</text>

            <text x="250" y="240" fill="red" font-size="18">${directions[4]}</text>
            <text x="200" y="205" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[4]] != false ? guaSymbol[directions[4]] : ""}</text>
            <text x="200" y="220" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[4]] != false ? guaName[directions[4]] : ""}</text>

            <line x1="60" y1="120" x2="240" y2="120" stroke="red" strokeWidth="2" />
            <line x1="60" y1="170" x2="240" y2="170" stroke="red" strokeWidth="2" />
            <line x1="120" y1="70" x2="120" y2="220" stroke="red" strokeWidth="2" />
            <line x1="180" y1="70" x2="180" y2="220" stroke="red" strokeWidth="2" />
        </svg>`;

    const main = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(baseSVG)));
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [image] = useImage(main);

    const rotateChart = () => {
        if (isSelected) {
            const node = shapeRef.current;
            const stage = trRef.current;
            const sideWidth = node.width() / 10;
            const sideHeight = node.height() / 10;
            const clickPointerX = stage.getStage().getPointerPosition()["x"] * canvasZoomValue;
            const clickPointerY = stage.getStage().getPointerPosition()["y"] * canvasZoomValue;
            if (clickPointerX >  node.x() + (sideWidth * 4) && clickPointerX <  node.x() + (sideWidth * 5) && clickPointerY < node.y() + sideHeight) {
                rotateLeft(directions);
            } else if (clickPointerX >  node.x() + (sideWidth * 5) && clickPointerX <  node.x() + (sideWidth * 6)  && clickPointerY < node.y() + sideHeight) {
                rotateRight(directions);
            }
            onChange({
                ...shapeProps,
                directions: shapeProps.directions,
                data: shapeProps.data
            });
        }
    }

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.setNode(shapeRef.current);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);
    
    return (
        <React.Fragment>
            <Image
                onClick={onSelect}
                onTap={onSelect}
                {...shapeProps}
                image={image}
                ref={shapeRef}
                draggable
                onDragEnd={e => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    });
                }}
                onTransformEnd={e => {
                    // transformer is changing scale
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        width: node.width() * scaleX,
                        height: node.height() * scaleY,
                        rotation: node.rotation()
                    });
                }}
                onMouseDown={rotateChart}
                onTouchStart={rotateChart}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    enabledAnchors={["top-right", "top-left", "bottom-right", "bottom-left"]}
                    anchorFill="#29A9E5"
                    anchorStrokeWidth={5}
                    rotationSnaps={[ 0, 45, 90, 135, 180, 225, 270, 315, 360]}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default StaticChartGrid;