import React from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
const AnnualMonthlyChartPie = ({ shapeProps, isSelected, onSelect, onChange, canvasZoomValue }) => {

    function rotateRight(arr){
        let last = arr.pop();
        arr.unshift(last);
        return arr;
    }

    function rotateLeft(arr){
        let first = arr.shift();
        arr.push(first)
        return arr;
    }

    const directions = shapeProps.directions;
    const sitting_arrow = shapeProps.data['facingSitting']['sitting_arrow']
    const facing_arrow = shapeProps.data['facingSitting']['facing_arrow']
    const sequence = shapeProps.data['sequence'];
    const annualOptions = shapeProps.annualOptions;
    const monthlyOptions = shapeProps.monthlyOptions;
    const annualValueGrid = shapeProps.data['annualFS']['grid'];
    const monthlyValueGrid = shapeProps.data['monthlyFS']['grid'];
    const baguaSelected = shapeProps.baguaSelected;
    const guaSymbol = shapeProps.data['guaSymbol'];
    const guaName = shapeProps.data['guaName'];

    const baseSVG =
        `<svg height="300px" width="300px" x="0px" y="0px" viewbox="0 0 207 142" xmlns="http://www.w3.org/2000/svg" version="1.1" style="transform: translate(0,0)">
            <svg x="126" y="5">
                ${isSelected ? "<path fill-rule='evenodd' d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z'/><path d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z'/>" : ""}
            </svg>
            <svg x="158" y="5">
                ${isSelected ? "<path fill-rule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'/><path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z'/>" : ""}
            </svg>

            <text x="35" y="60" fill="red" font-size="18">${directions[0]}</text>
            ${facing_arrow === directions[0] ? 
                "<svg x='13' y='28' fill='red'><path fill-rule='evenodd' d='M2 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1H3.707l10.147 10.146a.5.5 0 0 1-.708.708L3 3.707V8.5a.5.5 0 0 1-1 0v-6z'/></svg>"
            : ''}"
            <text x="27" y="35" fill="red" font-size="10">${facing_arrow === directions[0] ? sequence : ''}</text>
            ${sitting_arrow === directions[0] ? 
                "<line x1='21' y1='52' x2='50' y2='30' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="75" y="105" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[0] : monthlyValueGrid[0]}</text>
            <text x="97" y="95" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[0] : ""}</text>
            <text x="65" y="85" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[0]] != false ? guaSymbol[directions[0]] : ""}</text>
            <text x="65" y="105" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[0]] != false ? guaName[directions[0]] : ""}</text>

            <text x="145" y="50" fill="red" font-size="18">${directions[1]}</text>
            ${facing_arrow === directions[1] ? 
                "<svg x='142' y='17' fill='red'><path fill-rule='evenodd' d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z'/></svg>"
            : ''}"
            <text x="157" y="32" fill="red" font-size="10">${facing_arrow === directions[1] ? sequence : ''}</text>
            ${sitting_arrow === directions[1] ? 
                "<line x1='134' y1='33' x2='170' y2='33' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="142" y="93" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[1] : monthlyValueGrid[1]}</text>
            <text x="164" y="83" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[1] : ""}</text>
            <text x="133" y="80" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[1]] != false ? guaSymbol[directions[1]] : ""}</text>
            <text x="133" y="100" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[1]] != false ? guaName[directions[1]] : ""}</text>

            <text x="235" y="60" fill="red" font-size="18">${directions[2]}</text>
            ${facing_arrow === directions[2] ? 
                "<svg x='265' y='28' fill='red'><path fill-rule='evenodd' d='M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z'/></svg>"
            : ''}"
            <text x="245" y="35" fill="red" font-size="10">${facing_arrow === directions[2] ? sequence : ''}</text>
            ${sitting_arrow === directions[2] ? 
                "<line x1='257' y1='30' x2='281' y2='52' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="208" y="105" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[2] : monthlyValueGrid[2]}</text>
            <text x="230" y="95" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[2] : ""}</text>
            <text x="201" y="85" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[2]] != false ? guaSymbol[directions[2]] : ""}</text>
            <text x="201" y="105" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[2]] != false ? guaName[directions[2]] : ""}</text>

            <text x="16" y="150" fill="red" font-size="18">${directions[7]}</text>
            ${facing_arrow === directions[7] ? 
                "<svg x='0' y='136' fill='red'><path fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'/></svg>"
            : ''}"
            <text x="5" y="160" fill="red" font-size="10">${facing_arrow === directions[7] ? sequence : ''}</text>
            ${sitting_arrow === directions[7] ? 
                "<line x1='13' y1='129' x2='13' y2='163' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="60" y="163" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[7] : monthlyValueGrid[7]}</text>
            <text x="82" y="153" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[7] : ""}</text>
            <text x="45" y="150" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[7]] != false ? guaSymbol[directions[7]] : ""}</text>
            <text x="45" y="170" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[7]] != false ? guaName[directions[7]] : ""}</text>

            <text x="257" y="150" fill="red" font-size="18">${directions[3]}</text>
            ${facing_arrow === directions[3] ? 
                "<svg x='285' y='136' fill='red'><path fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/></svg>"
            : ''}"
            <text x="275" y="160" fill="red" font-size="10">${facing_arrow === directions[3] ? sequence : ''}</text>
            ${sitting_arrow === directions[3] ? 
                "<line x1='289' y1='129' x2='289' y2='163' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="223" y="163" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[3] : monthlyValueGrid[3]}</text>
            <text x="245" y="153" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[3] : ""}</text>
            <text x="221" y="150" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[3]] != false ? guaSymbol[directions[3]] : ""}</text>
            <text x="221" y="170" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[3]] != false ? guaName[directions[3]] : ""}</text>

            <text x="35" y="240" fill="red" font-size="18">${directions[6]}</text>
            ${facing_arrow === directions[6] ? 
                "<svg x='15' y='245' fill='red'><path fill-rule='evenodd' d='M2 13.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1H3.707L13.854 2.854a.5.5 0 0 0-.708-.708L3 12.293V7.5a.5.5 0 0 0-1 0v6z'/></svg>"
            : ''}"
            <text x="27" y="260" fill="red" font-size="10">${facing_arrow === directions[6] ? sequence : ''}</text>
            ${sitting_arrow === directions[6] ? 
                "<line x1='21' y1='235' x2='50' y2='257' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="75" y="223" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[6] : monthlyValueGrid[6]}</text>
            <text x="97" y="213" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[6] : ""}</text>
            <text x="65" y="215" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[6]] != false ? guaSymbol[directions[6]] : ""}</text>
            <text x="65" y="235" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[6]] != false ? guaName[directions[6]] : ""}</text>

            <text x="145" y="255" fill="red" font-size="18">${directions[5]}</text>
            ${facing_arrow === directions[5] ? 
                "<svg x='142' y='260' fill='red'><path fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/></svg>"
            : ''}"
            <text x="157" y="270" fill="red" font-size="10">${facing_arrow === directions[5] ? sequence : ''}</text>
            ${sitting_arrow === directions[5] ? 
                "<line x1='134' y1='262' x2='168' y2='262' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="142" y="235" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[5] : monthlyValueGrid[5]}</text>
            <text x="164" y="225" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[5] : ""}</text>
            <text x="133" y="220" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[5]] != false ? guaSymbol[directions[5]] : ""}</text>
            <text x="133" y="240" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[5]] != false ? guaName[directions[5]] : ""}</text>

            <text x="235" y="240" fill="red" font-size="18">${directions[4]}</text>
            ${facing_arrow === directions[4] ? 
                "<svg x='265' y='242' fill='red'><path fill-rule='evenodd' d='M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z'/></svg>"
            : ''}"
            <text x="245" y="260" fill="red" font-size="10">${facing_arrow === directions[4] ? sequence : ''}</text>
            ${sitting_arrow === directions[4] ? 
                "<line x1='255' y1='255' x2='279' y2='232' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="208" y="223" fill="${annualOptions == true ? "blue" : "green"}" font-size="31">${annualOptions == true ? annualValueGrid[4] : monthlyValueGrid[4]}</text>
            <text x="230" y="213" fill="green" font-size="16">${annualOptions == true && monthlyOptions == true ? monthlyValueGrid[4] : ""}</text>
            <text x="201" y="215" fill="#5e5e5e" font-size="35" class="bagua">${baguaSelected[directions[4]] != false ? guaSymbol[directions[4]] : ""}</text>
            <text x="201" y="235" fill="#5e5e5e" font-size="17" class="bagua">${baguaSelected[directions[4]] != false ? guaName[directions[4]] : ""}</text>

            <line x1="97" y1="40" x2="210" y2="260" stroke="red" strokeWidth="2" />
            <line x1="203" y1="40" x2="95" y2="260" stroke="red" strokeWidth="2" />
            <line x1="30" y1="100" x2="265" y2="190" stroke="red" strokeWidth="2" />
            <line x1="30" y1="195" x2="265" y2="100" stroke="red" strokeWidth="2" />
        </svg>`;

    const main = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(baseSVG)));
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [image] = useImage(main);

    const rotateChart = () => {
        if (isSelected) {
            const node = shapeRef.current;
            const stage = trRef.current;
            const sideWidth = node.width() / 10;
            const sideHeight = node.height() / 10;
            const clickPointerX = stage.getStage().getPointerPosition()["x"] * canvasZoomValue;
            const clickPointerY = stage.getStage().getPointerPosition()["y"] * canvasZoomValue;
            if (clickPointerX >  node.x() + (sideWidth * 4) && clickPointerX <  node.x() + (sideWidth * 5) && clickPointerY < node.y() + sideHeight) {
                rotateLeft(directions);
                rotateLeft(annualValueGrid);
                rotateLeft(monthlyValueGrid);
            } else if (clickPointerX >  node.x() + (sideWidth * 5) && clickPointerX <  node.x() + (sideWidth * 6)  && clickPointerY < node.y() + sideHeight) {
                rotateRight(directions);
                rotateRight(annualValueGrid);
                rotateRight(monthlyValueGrid);
            }
            onChange({
                ...shapeProps,
                directions: shapeProps.directions,
                data: shapeProps.data
            });
        }
    }

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.setNode(shapeRef.current);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Image
                onClick={onSelect}
                onTap={onSelect}
                {...shapeProps}
                image={image}
                ref={shapeRef}
                draggable
                onDragEnd={e => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    });
                }}
                onTransformEnd={e => {
                    // transformer is changing scale
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        width: node.width() * scaleX,
                        height: node.height() * scaleY,
                        rotation: node.rotation()
                    });
                }}
                onMouseDown={rotateChart}
                onTouchStart={rotateChart}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    enabledAnchors={["top-right", "top-left", "bottom-right", "bottom-left"]}
                    anchorFill="#29A9E5"
                    anchorStrokeWidth={5}
                    rotationSnaps={[ 0, 45, 90, 135, 180, 225, 270, 315, 360]}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default AnnualMonthlyChartPie;