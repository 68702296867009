import React from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";
import MountainsChartPNG from "../../images/24-mountains-chart.svg";
const MountainsChart = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [svgChart] = useImage(MountainsChartPNG);

    React.useEffect(() => {
        if (isSelected) {
             // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);
    
    return (
        <React.Fragment>
            <Image
                onClick={onSelect}
                onTap={onSelect}
                {...shapeProps}
                image={svgChart}
                ref={shapeRef}
                draggable
                onDragEnd={e => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    });
                }}
                onTransformEnd={e => {
                    // transformer is changing scale
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        width: node.width() * scaleX,
                        height: node.height() * scaleY,
                        rotation: node.rotation()
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    enabledAnchors={["top-right", "top-left", "bottom-right", "bottom-left"]}
                    anchorFill="#29A9E5"
                    anchorStrokeWidth={5}
                    rotationSnaps={[ 0, 45, 90, 135, 180, 225, 270, 315, 360]}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default MountainsChart;