import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "inputHourFirst", "buttonHourFirst", "inputMinuteFirst", "buttonMinuteFirst",
        "inputHourSecond", "buttonHourSecond", "inputMinuteSecond", "buttonMinuteSecond", "inputDateSecond", "checkBoxSecond",
        "inputHourThird", "buttonHourThird", "inputMinuteThird", "buttonMinuteThird", "inputDateThird", "checkBoxThird",
        "inputHourFourth", "buttonHourFourth", "inputMinuteFourth", "buttonMinuteFourth", "inputDateFourth", "checkBoxFourth"
    ]
    connect() {
        this.inputHourFirstTarget.disabled = this.buttonHourFirstTarget.checked
        this.inputMinuteFirstTarget.disabled = this.buttonHourFirstTarget.checked
        this.inputMinuteFirstTarget.disabled = this.buttonMinuteFirstTarget.checked
        this.buttonMinuteFirstTarget.disabled = this.buttonHourFirstTarget.checked

        this.buttonHourSecondTarget.disabled = !this.checkBoxSecondTarget.checked
        this.inputDateSecondTarget.disabled = !this.checkBoxSecondTarget.checked
        this.inputHourSecondTarget.disabled = this.buttonHourSecondTarget.checked
        this.inputMinuteSecondTarget.disabled = this.buttonHourSecondTarget.checked
        this.inputMinuteSecondTarget.disabled = this.buttonMinuteSecondTarget.checked
        this.buttonMinuteSecondTarget.disabled = this.buttonHourSecondTarget.checked

        this.buttonHourThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.inputDateThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.inputHourThirdTarget.disabled = this.buttonHourThirdTarget.checked
        this.inputMinuteThirdTarget.disabled = this.buttonHourThirdTarget.checked
        this.inputMinuteThirdTarget.disabled = this.buttonMinuteThirdTarget.checked
        this.buttonMinuteThirdTarget.disabled = this.buttonHourThirdTarget.checked

        this.buttonHourFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.inputDateFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.inputHourFourthTarget.disabled = this.buttonHourFourthTarget.checked
        this.inputMinuteFourthTarget.disabled = this.buttonHourFourthTarget.checked
        this.inputMinuteFourthTarget.disabled = this.buttonMinuteFourthTarget.checked
        this.buttonMinuteFourthTarget.disabled = this.buttonHourFourthTarget.checked
    }

    unknownHourFirst() {
        if (this.buttonHourFirstTarget.checked) {
            this.inputHourFirstTarget.disabled = true
            this.inputMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.checked = true
        } else {
            this.inputHourFirstTarget.disabled = false
            this.inputMinuteFirstTarget.disabled = false
            this.buttonMinuteFirstTarget.disabled = false
            this.buttonMinuteFirstTarget.checked = false
        }
    }

    unknownMinuteFirst() {
        if (this.buttonMinuteFirstTarget.checked) {
            this.inputMinuteFirstTarget.disabled = true
        } else {
            this.inputMinuteFirstTarget.disabled = false
        }
    }

    unknownHourSecond() {
        if (this.buttonHourSecondTarget.checked) {
            this.inputHourSecondTarget.disabled = true
            this.inputMinuteSecondTarget.disabled = true
            this.buttonMinuteSecondTarget.disabled = true
            this.buttonMinuteSecondTarget.checked = true
        } else {
            this.inputHourSecondTarget.disabled = false
            this.inputMinuteSecondTarget.disabled = false
            this.buttonMinuteSecondTarget.disabled = false
            this.buttonMinuteSecondTarget.checked = false
        }
    }

    unknownMinuteSecond() {
        if (this.buttonMinuteSecondTarget.checked) {
            this.inputMinuteSecondTarget.disabled = true
        } else {
            this.inputMinuteSecondTarget.disabled = false
        }
    }

    unknownHourThird() {
        if (this.buttonHourThirdTarget.checked) {
            this.inputHourThirdTarget.disabled = true
            this.inputMinuteThirdTarget.disabled = true
            this.buttonMinuteThirdTarget.disabled = true
            this.buttonMinuteThirdTarget.checked = true
        } else {
            this.inputHourThirdTarget.disabled = false
            this.inputMinuteThirdTarget.disabled = false
            this.buttonMinuteThirdTarget.disabled = false
            this.buttonMinuteThirdTarget.checked = false
        }
    }

    unknownMinuteThird() {
        if (this.buttonMinuteThirdTarget.checked) {
            this.inputMinuteThirdTarget.disabled = true
        } else {
            this.inputMinuteThirdTarget.disabled = false
        }
    }

    unknownHourFourth() {
        if (this.buttonHourFourthTarget.checked) {
            this.inputHourFourthTarget.disabled = true
            this.inputMinuteFourthTarget.disabled = true
            this.buttonMinuteFourthTarget.disabled = true
            this.buttonMinuteFourthTarget.checked = true
        } else {
            this.inputHourFourthTarget.disabled = false
            this.inputMinuteFourthTarget.disabled = false
            this.buttonMinuteFourthTarget.disabled = false
            this.buttonMinuteFourthTarget.checked = false
        }
    }

    unknownMinuteFourth() {
        if (this.buttonMinuteFourthTarget.checked) {
            this.inputMinuteFourthTarget.disabled = true
        } else {
            this.inputMinuteFourthTarget.disabled = false
        }
    }

    disableFormSecond() {
        this.inputDateSecondTarget.disabled = !this.checkBoxSecondTarget.checked
        this.inputHourSecondTarget.disabled = !this.checkBoxSecondTarget.checked
        this.inputMinuteSecondTarget.disabled = !this.checkBoxSecondTarget.checked
        this.buttonHourSecondTarget.checked = !this.checkBoxSecondTarget.checked
        this.buttonHourSecondTarget.disabled = !this.checkBoxSecondTarget.checked
        this.buttonMinuteSecondTarget.checked = !this.checkBoxSecondTarget.checked
        this.buttonMinuteSecondTarget.disabled = !this.checkBoxSecondTarget.checked
    }

    disableFormThird() {
        this.inputDateThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.inputHourThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.inputMinuteThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.buttonHourThirdTarget.checked = !this.checkBoxThirdTarget.checked
        this.buttonHourThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.buttonMinuteThirdTarget.checked = !this.checkBoxThirdTarget.checked
        this.buttonMinuteThirdTarget.disabled = !this.checkBoxThirdTarget.checked
    }

    disableFormFourth() {
        this.inputDateFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.inputHourFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.inputMinuteFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.buttonHourFourthTarget.checked = !this.checkBoxFourthTarget.checked
        this.buttonHourFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.buttonMinuteFourthTarget.checked = !this.checkBoxFourthTarget.checked
        this.buttonMinuteFourthTarget.disabled = !this.checkBoxFourthTarget.checked
    }
}