import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "inputHourFirst", "buttonHourFirst", "inputMinuteFirst", "buttonMinuteFirst",
        "inputHourSecond", "buttonHourSecond", "inputMinuteSecond", "buttonMinuteSecond",
        "inputHourThird", "buttonHourThird", "inputMinuteThird", "buttonMinuteThird",
        "inputHourFourth", "buttonHourFourth", "inputMinuteFourth", "buttonMinuteFourth",
        "inputHourFifth", "buttonHourFifth", "inputMinuteFifth", "buttonMinuteFifth",
        "inputHourSixth", "buttonHourSixth", "inputMinuteSixth", "buttonMinuteSixth",
        "inputHourSeventh", "buttonHourSeventh", "inputMinuteSeventh", "buttonMinuteSeventh",
        "inputHourEighth", "buttonHourEighth", "inputMinuteEighth", "buttonMinuteEighth",
        "nameThird", "genderThird", "inputDateThird", "checkBoxThird",
        "nameFourth", "genderFourth", "inputDateFourth", "checkBoxFourth",
        "nameFifth", "genderFifth", "inputDateFifth", "checkBoxFifth",
        "nameSixth", "genderSixth", "inputDateSixth", "checkBoxSixth",
        "nameSeventh", "genderSeventh", "inputDateSeventh", "checkBoxSeventh",
        "nameEighth", "genderEighth", "inputDateEighth", "checkBoxEighth"
    ]
    connect() {
        this.buttonHourFirstTarget.checked = true
        this.buttonHourSecondTarget.checked = true
        this.unknownHourFirst()
        this.unknownMinuteFirst()
        this.unknownHourSecond()
        this.unknownMinuteSecond()
        this.unknownHourThird()
        this.unknownMinuteThird()
        this.unknownHourFourth()
        this.unknownMinuteFourth()
        this.unknownHourFifth()
        this.unknownMinuteFifth()
        this.unknownHourSixth()
        this.unknownMinuteSixth()
        this.unknownHourSeventh()
        this.unknownMinuteSeventh()
        this.unknownHourEighth()
        this.unknownMinuteEighth()
        this.disableFormThird()
        this.disableFormFourth()
        this.disableFormFifth()
        this.disableFormSixth()
        this.disableFormSeventh()
        this.disableFormEighth()
    }

    unknownHourFirst() {
        if (this.buttonHourFirstTarget.checked) {
            this.inputHourFirstTarget.disabled = true
            this.inputMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.disabled = true
            this.buttonMinuteFirstTarget.checked = true
        } else {
            this.inputHourFirstTarget.disabled = false
            this.inputMinuteFirstTarget.disabled = false
            this.buttonMinuteFirstTarget.disabled = false
            this.buttonMinuteFirstTarget.checked = false
        }
    }

    unknownMinuteFirst() {
        if (this.buttonMinuteFirstTarget.checked) {
            this.inputMinuteFirstTarget.disabled = true
        } else {
            this.inputMinuteFirstTarget.disabled = false
        }
    }

    unknownHourSecond() {
        if (this.buttonHourSecondTarget.checked) {
            this.inputHourSecondTarget.disabled = true
            this.inputMinuteSecondTarget.disabled = true
            this.buttonMinuteSecondTarget.disabled = true
            this.buttonMinuteSecondTarget.checked = true
        } else {
            this.inputHourSecondTarget.disabled = false
            this.inputMinuteSecondTarget.disabled = false
            this.buttonMinuteSecondTarget.disabled = false
            this.buttonMinuteSecondTarget.checked = false
        }
    }

    unknownMinuteSecond() {
        if (this.buttonMinuteSecondTarget.checked) {
            this.inputMinuteSecondTarget.disabled = true
        } else {
            this.inputMinuteSecondTarget.disabled = false
        }
    }

    unknownHourThird() {
        if (this.buttonHourThirdTarget.checked) {
            this.inputHourThirdTarget.disabled = true
            this.inputMinuteThirdTarget.disabled = true
            this.buttonMinuteThirdTarget.disabled = true
            this.buttonMinuteThirdTarget.checked = true
        } else {
            this.inputHourThirdTarget.disabled = false
            this.inputMinuteThirdTarget.disabled = false
            this.buttonMinuteThirdTarget.disabled = false
            this.buttonMinuteThirdTarget.checked = false
        }
    }

    unknownMinuteThird() {
        if (this.buttonMinuteThirdTarget.checked) {
            this.inputMinuteThirdTarget.disabled = true
        } else {
            this.inputMinuteThirdTarget.disabled = false
        }
    }

    unknownHourFourth() {
        if (this.buttonHourFourthTarget.checked) {
            this.inputHourFourthTarget.disabled = true
            this.inputMinuteFourthTarget.disabled = true
            this.buttonMinuteFourthTarget.disabled = true
            this.buttonMinuteFourthTarget.checked = true
        } else {
            this.inputHourFourthTarget.disabled = false
            this.inputMinuteFourthTarget.disabled = false
            this.buttonMinuteFourthTarget.disabled = false
            this.buttonMinuteFourthTarget.checked = false
        }
    }

    unknownMinuteFourth() {
        if (this.buttonMinuteFourthTarget.checked) {
            this.inputMinuteFourthTarget.disabled = true
        } else {
            this.inputMinuteFourthTarget.disabled = false
        }
    }

    unknownHourFifth() {
        if (this.buttonHourFifthTarget.checked) {
            this.inputHourFifthTarget.disabled = true
            this.inputMinuteFifthTarget.disabled = true
            this.buttonMinuteFifthTarget.disabled = true
            this.buttonMinuteFifthTarget.checked = true
        } else {
            this.inputHourFifthTarget.disabled = false
            this.inputMinuteFifthTarget.disabled = false
            this.buttonMinuteFifthTarget.disabled = false
            this.buttonMinuteFifthTarget.checked = false
        }
    }

    unknownMinuteFifth() {
        if (this.buttonMinuteFifthTarget.checked) {
            this.inputMinuteFifthTarget.disabled = true
        } else {
            this.inputMinuteFifthTarget.disabled = false
        }
    }

    unknownHourSixth() {
        if (this.buttonHourSixthTarget.checked) {
            this.inputHourSixthTarget.disabled = true
            this.inputMinuteSixthTarget.disabled = true
            this.buttonMinuteSixthTarget.disabled = true
            this.buttonMinuteSixthTarget.checked = true
        } else {
            this.inputHourSixthTarget.disabled = false
            this.inputMinuteSixthTarget.disabled = false
            this.buttonMinuteSixthTarget.disabled = false
            this.buttonMinuteSixthTarget.checked = false
        }
    }

    unknownMinuteSixth() {
        if (this.buttonMinuteSixthTarget.checked) {
            this.inputMinuteSixthTarget.disabled = true
        } else {
            this.inputMinuteSixthTarget.disabled = false
        }
    }

    unknownHourSeventh() {
        if (this.buttonHourSeventhTarget.checked) {
            this.inputHourSeventhTarget.disabled = true
            this.inputMinuteSeventhTarget.disabled = true
            this.buttonMinuteSeventhTarget.disabled = true
            this.buttonMinuteSeventhTarget.checked = true
        } else {
            this.inputHourSeventhTarget.disabled = false
            this.inputMinuteSeventhTarget.disabled = false
            this.buttonMinuteSeventhTarget.disabled = false
            this.buttonMinuteSeventhTarget.checked = false
        }
    }

    unknownMinuteSeventh() {
        if (this.buttonMinuteSeventhTarget.checked) {
            this.inputMinuteSeventhTarget.disabled = true
        } else {
            this.inputMinuteSeventhTarget.disabled = false
        }
    }

    unknownHourEighth() {
        if (this.buttonHourEighthTarget.checked) {
            this.inputHourEighthTarget.disabled = true
            this.inputMinuteEighthTarget.disabled = true
            this.buttonMinuteEighthTarget.disabled = true
            this.buttonMinuteEighthTarget.checked = true
        } else {
            this.inputHourEighthTarget.disabled = false
            this.inputMinuteEighthTarget.disabled = false
            this.buttonMinuteEighthTarget.disabled = false
            this.buttonMinuteEighthTarget.checked = false
        }
    }

    unknownMinuteEighth() {
        if (this.buttonMinuteEighthTarget.checked) {
            this.inputMinuteEighthTarget.disabled = true
        } else {
            this.inputMinuteEighthTarget.disabled = false
        }
    }

    disableFormThird() {
        this.nameThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.genderThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.inputDateThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.inputHourThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.inputMinuteThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.buttonHourThirdTarget.checked = !this.checkBoxThirdTarget.checked
        this.buttonHourThirdTarget.disabled = !this.checkBoxThirdTarget.checked
        this.buttonMinuteThirdTarget.checked = !this.checkBoxThirdTarget.checked
        this.buttonMinuteThirdTarget.disabled = !this.checkBoxThirdTarget.checked
    }

    disableFormFourth() {
        this.nameFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.genderFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.inputDateFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.inputHourFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.inputMinuteFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.buttonHourFourthTarget.checked = !this.checkBoxFourthTarget.checked
        this.buttonHourFourthTarget.disabled = !this.checkBoxFourthTarget.checked
        this.buttonMinuteFourthTarget.checked = !this.checkBoxFourthTarget.checked
        this.buttonMinuteFourthTarget.disabled = !this.checkBoxFourthTarget.checked
    }

    disableFormFifth() {
        this.nameFifthTarget.disabled = !this.checkBoxFifthTarget.checked
        this.genderFifthTarget.disabled = !this.checkBoxFifthTarget.checked
        this.inputDateFifthTarget.disabled = !this.checkBoxFifthTarget.checked
        this.inputHourFifthTarget.disabled = !this.checkBoxFifthTarget.checked
        this.inputMinuteFifthTarget.disabled = !this.checkBoxFifthTarget.checked
        this.buttonHourFifthTarget.checked = !this.checkBoxFifthTarget.checked
        this.buttonHourFifthTarget.disabled = !this.checkBoxFifthTarget.checked
        this.buttonMinuteFifthTarget.checked = !this.checkBoxFifthTarget.checked
        this.buttonMinuteFifthTarget.disabled = !this.checkBoxFifthTarget.checked
    }

    disableFormSixth() {
        this.nameSixthTarget.disabled = !this.checkBoxSixthTarget.checked
        this.genderSixthTarget.disabled = !this.checkBoxSixthTarget.checked
        this.inputDateSixthTarget.disabled = !this.checkBoxSixthTarget.checked
        this.inputHourSixthTarget.disabled = !this.checkBoxSixthTarget.checked
        this.inputMinuteSixthTarget.disabled = !this.checkBoxSixthTarget.checked
        this.buttonHourSixthTarget.checked = !this.checkBoxSixthTarget.checked
        this.buttonHourSixthTarget.disabled = !this.checkBoxSixthTarget.checked
        this.buttonMinuteSixthTarget.checked = !this.checkBoxSixthTarget.checked
        this.buttonMinuteSixthTarget.disabled = !this.checkBoxSixthTarget.checked
    }

    disableFormSeventh() {
        this.nameSeventhTarget.disabled = !this.checkBoxSeventhTarget.checked
        this.genderSeventhTarget.disabled = !this.checkBoxSeventhTarget.checked
        this.inputDateSeventhTarget.disabled = !this.checkBoxSeventhTarget.checked
        this.inputHourSeventhTarget.disabled = !this.checkBoxSeventhTarget.checked
        this.inputMinuteSeventhTarget.disabled = !this.checkBoxSeventhTarget.checked
        this.buttonHourSeventhTarget.checked = !this.checkBoxSeventhTarget.checked
        this.buttonHourSeventhTarget.disabled = !this.checkBoxSeventhTarget.checked
        this.buttonMinuteSeventhTarget.checked = !this.checkBoxSeventhTarget.checked
        this.buttonMinuteSeventhTarget.disabled = !this.checkBoxSeventhTarget.checked
    }

    disableFormEighth() {
        this.nameEighthTarget.disabled = !this.checkBoxEighthTarget.checked
        this.genderEighthTarget.disabled = !this.checkBoxEighthTarget.checked
        this.inputDateEighthTarget.disabled = !this.checkBoxEighthTarget.checked
        this.inputHourEighthTarget.disabled = !this.checkBoxEighthTarget.checked
        this.inputMinuteEighthTarget.disabled = !this.checkBoxEighthTarget.checked
        this.buttonHourEighthTarget.checked = !this.checkBoxEighthTarget.checked
        this.buttonHourEighthTarget.disabled = !this.checkBoxEighthTarget.checked
        this.buttonMinuteEighthTarget.checked = !this.checkBoxEighthTarget.checked
        this.buttonMinuteEighthTarget.disabled = !this.checkBoxEighthTarget.checked
    }
}