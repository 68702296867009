import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 
        "editButton",
        "nameFirst", "genderFirst", "inputDateFirst", "inputHourFirst", "buttonHourFirst", "inputMinuteFirst", "buttonMinuteFirst",
        "nameFirstForm", "genderFirstForm", "inputDateFirstForm", "inputHourFirstForm", "inputMinuteFirstForm", "buttonHourFirstForm", "buttonMinuteFirstForm", "checkBoxFirstForm",
        
        "nameSecond", "genderSecond", "inputDateSecond", "inputHourSecond", "buttonHourSecond", "inputMinuteSecond", "buttonMinuteSecond",
        "nameSecondForm", "genderSecondForm", "inputDateSecondForm", "inputHourSecondForm", "inputMinuteSecondForm", "buttonHourSecondForm", "buttonMinuteSecondForm", "checkBoxSecondForm",

        "nameThird", "genderThird", "inputDateThird", "inputHourThird", "buttonHourThird", "inputMinuteThird", "buttonMinuteThird",
        "nameThirdForm", "genderThirdForm", "inputDateThirdForm", "inputHourThirdForm", "inputMinuteThirdForm", "buttonHourThirdForm", "buttonMinuteThirdForm", "checkBoxThirdForm",

        "nameFourth", "genderFourth", "inputDateFourth", "inputHourFourth", "buttonHourFourth", "inputMinuteFourth", "buttonMinuteFourth",
        "nameFourthForm", "genderFourthForm", "inputDateFourthForm", "inputHourFourthForm", "inputMinuteFourthForm", "buttonHourFourthForm", "buttonMinuteFourthForm", "checkBoxFourthForm",

        "nameFifth", "genderFifth", "inputDateFifth", "inputHourFifth", "buttonHourFifth", "inputMinuteFifth", "buttonMinuteFifth",
        "nameFifthForm", "genderFifthForm", "inputDateFifthForm", "inputHourFifthForm", "inputMinuteFifthForm", "buttonHourFifthForm", "buttonMinuteFifthForm", "checkBoxFifthForm",

        "nameSixth", "genderSixth", "inputDateSixth", "inputHourSixth", "buttonHourSixth", "inputMinuteSixth", "buttonMinuteSixth",
        "nameSixthForm", "genderSixthForm", "inputDateSixthForm", "inputHourSixthForm", "inputMinuteSixthForm", "buttonHourSixthForm", "buttonMinuteSixthForm", "checkBoxSixthForm",

        "nameSeventh", "genderSeventh", "inputDateSeventh", "inputHourSeventh", "buttonHourSeventh", "inputMinuteSeventh", "buttonMinuteSeventh",
        "nameSeventhForm", "genderSeventhForm", "inputDateSeventhForm", "inputHourSeventhForm", "inputMinuteSeventhForm", "buttonHourSeventhForm", "buttonMinuteSeventhForm", "checkBoxSeventhForm",

        "nameEighth", "genderEighth", "inputDateEighth", "inputHourEighth", "buttonHourEighth", "inputMinuteEighth", "buttonMinuteEighth",
        "nameEighthForm", "genderEighthForm", "inputDateEighthForm", "inputHourEighthForm", "inputMinuteEighthForm", "buttonHourEighthForm", "buttonMinuteEighthForm", "checkBoxEighthForm"
    ]
    connect() {
        this.disableFormFirst()
        this.disableFormSecond()
        this.disableFormThird()
        this.disableFormFourth()
        this.disableFormFifth()
        this.disableFormSixth()
        this.disableFormSeventh()
        this.disableFormEighth()
    }

    unknownHourFirst() {
        if (this.hasInputHourFirstTarget) {
            if (this.buttonHourFirstTarget.checked) {
                this.inputHourFirstTarget.disabled = true
                this.inputMinuteFirstTarget.disabled = true
                this.buttonMinuteFirstTarget.disabled = true
                this.buttonMinuteFirstTarget.checked = true
            } else {
                this.inputHourFirstTarget.disabled = false
                this.inputMinuteFirstTarget.disabled = false
                this.buttonMinuteFirstTarget.disabled = false
                this.buttonMinuteFirstTarget.checked = false
            }
        }

        if (this.hasInputHourFirstFormTarget) {
            if (this.buttonHourFirstFormTarget.checked) {
                this.inputHourFirstFormTarget.disabled = true
                this.inputMinuteFirstFormTarget.disabled = true
                this.buttonMinuteFirstFormTarget.disabled = true
                this.buttonMinuteFirstFormTarget.checked = true
            } else {
                this.inputHourFirstFormTarget.disabled = false
                this.inputMinuteFirstFormTarget.disabled = false
                this.buttonMinuteFirstFormTarget.disabled = false
                this.buttonMinuteFirstFormTarget.checked = false
            }
        }
    }

    unknownMinuteFirst() {
        if (this.hasInputHourFirstTarget) {
            if (this.buttonMinuteFirstTarget.checked) {
                this.inputMinuteFirstTarget.disabled = true
            } else {
                this.inputMinuteFirstTarget.disabled = false
            }
        }

        if (this.hasInputHourFirstFormTarget) {
            if (this.buttonMinuteFirstFormTarget.checked) {
                this.inputMinuteFirstFormTarget.disabled = true
            } else {
                this.inputMinuteFirstFormTarget.disabled = false
            }
        }
    }

    unknownHourSecond() {
        if (this.hasInputHourSecondTarget) {
            if (this.buttonHourSecondTarget.checked) {
                this.inputHourSecondTarget.disabled = true
                this.inputMinuteSecondTarget.disabled = true
                this.buttonMinuteSecondTarget.disabled = true
                this.buttonMinuteSecondTarget.checked = true
            } else {
                this.inputHourSecondTarget.disabled = false
                this.inputMinuteSecondTarget.disabled = false
                this.buttonMinuteSecondTarget.disabled = false
                this.buttonMinuteSecondTarget.checked = false
            }
        }

        if (this.hasInputHourSecondFormTarget) {
            if (this.buttonHourSecondFormTarget.checked) {
                this.inputHourSecondFormTarget.disabled = true
                this.inputMinuteSecondFormTarget.disabled = true
                this.buttonMinuteSecondFormTarget.disabled = true
                this.buttonMinuteSecondFormTarget.checked = true
            } else {
                this.inputHourSecondFormTarget.disabled = false
                this.inputMinuteSecondFormTarget.disabled = false
                this.buttonMinuteSecondFormTarget.disabled = false
                this.buttonMinuteSecondFormTarget.checked = false
            }
        }
    }

    unknownMinuteSecond() {
        if (this.hasInputHourSecondTarget) {
            if (this.buttonMinuteSecondTarget.checked) {
                this.inputMinuteSecondTarget.disabled = true
            } else {
                this.inputMinuteSecondTarget.disabled = false
            }
        }

        if (this.hasInputHourSecondFormTarget) {
            if (this.buttonMinuteSecondFormTarget.checked) {
                this.inputMinuteSecondFormTarget.disabled = true
            } else {
                this.inputMinuteSecondFormTarget.disabled = false
            }
        }
    }

    unknownHourThird() {
        if (this.hasInputHourThirdTarget) {
            if (this.buttonHourThirdTarget.checked) {
                this.inputHourThirdTarget.disabled = true
                this.inputMinuteThirdTarget.disabled = true
                this.buttonMinuteThirdTarget.disabled = true
                this.buttonMinuteThirdTarget.checked = true
            } else {
                this.inputHourThirdTarget.disabled = false
                this.inputMinuteThirdTarget.disabled = false
                this.buttonMinuteThirdTarget.disabled = false
                this.buttonMinuteThirdTarget.checked = false
            }
        }

        if (this.hasInputHourThirdFormTarget) {
            if (this.buttonHourThirdFormTarget.checked) {
                this.inputHourThirdFormTarget.disabled = true
                this.inputMinuteThirdFormTarget.disabled = true
                this.buttonMinuteThirdFormTarget.disabled = true
                this.buttonMinuteThirdFormTarget.checked = true
            } else {
                this.inputHourThirdFormTarget.disabled = false
                this.inputMinuteThirdFormTarget.disabled = false
                this.buttonMinuteThirdFormTarget.disabled = false
                this.buttonMinuteThirdFormTarget.checked = false
            }
        }
    }

    unknownMinuteThird() {
        if (this.hasInputHourThirdTarget) {
            if (this.buttonMinuteThirdTarget.checked) {
                this.inputMinuteThirdTarget.disabled = true
            } else {
                this.inputMinuteThirdTarget.disabled = false
            }
        }

        if (this.hasInputHourThirdFormTarget) {
            if (this.buttonMinuteThirdFormTarget.checked) {
                this.inputMinuteThirdFormTarget.disabled = true
            } else {
                this.inputMinuteThirdFormTarget.disabled = false
            }
        }
    }

    unknownHourFourth() {
        if (this.hasInputHourFourthTarget) {
            if (this.buttonHourFourthTarget.checked) {
                this.inputHourFourthTarget.disabled = true
                this.inputMinuteFourthTarget.disabled = true
                this.buttonMinuteFourthTarget.disabled = true
                this.buttonMinuteFourthTarget.checked = true
            } else {
                this.inputHourFourthTarget.disabled = false
                this.inputMinuteFourthTarget.disabled = false
                this.buttonMinuteFourthTarget.disabled = false
                this.buttonMinuteFourthTarget.checked = false
            }
        }

        if (this.hasInputHourFourthFormTarget) {
            if (this.buttonHourFourthFormTarget.checked) {
                this.inputHourFourthFormTarget.disabled = true
                this.inputMinuteFourthFormTarget.disabled = true
                this.buttonMinuteFourthFormTarget.disabled = true
                this.buttonMinuteFourthFormTarget.checked = true
            } else {
                this.inputHourFourthFormTarget.disabled = false
                this.inputMinuteFourthFormTarget.disabled = false
                this.buttonMinuteFourthFormTarget.disabled = false
                this.buttonMinuteFourthFormTarget.checked = false
            }
        }
    }

    unknownMinuteFourth() {
        if (this.hasInputHourFourthTarget) {
            if (this.buttonMinuteFourthTarget.checked) {
                this.inputMinuteFourthTarget.disabled = true
            } else {
                this.inputMinuteFourthTarget.disabled = false
            }
        }

        if (this.hasInputHourFourthFormTarget) {
            if (this.buttonMinuteFourthFormTarget.checked) {
                this.inputMinuteFourthFormTarget.disabled = true
            } else {
                this.inputMinuteFourthFormTarget.disabled = false
            }
        }
    }

    unknownHourFifth() {
        if (this.hasInputHourFifthTarget) {
            if (this.buttonHourFifthTarget.checked) {
                this.inputHourFifthTarget.disabled = true
                this.inputMinuteFifthTarget.disabled = true
                this.buttonMinuteFifthTarget.disabled = true
                this.buttonMinuteFifthTarget.checked = true
            } else {
                this.inputHourFifthTarget.disabled = false
                this.inputMinuteFifthTarget.disabled = false
                this.buttonMinuteFifthTarget.disabled = false
                this.buttonMinuteFifthTarget.checked = false
            }
        }

        if (this.hasInputHourFifthFormTarget) {
            if (this.buttonHourFifthFormTarget.checked) {
                this.inputHourFifthFormTarget.disabled = true
                this.inputMinuteFifthFormTarget.disabled = true
                this.buttonMinuteFifthFormTarget.disabled = true
                this.buttonMinuteFifthFormTarget.checked = true
            } else {
                this.inputHourFifthFormTarget.disabled = false
                this.inputMinuteFifthFormTarget.disabled = false
                this.buttonMinuteFifthFormTarget.disabled = false
                this.buttonMinuteFifthFormTarget.checked = false
            }
        }
    }

    unknownMinuteFifth() {
        if (this.hasInputHourFifthTarget) {
            if (this.buttonMinuteFifthTarget.checked) {
                this.inputMinuteFifthTarget.disabled = true
            } else {
                this.inputMinuteFifthTarget.disabled = false
            }
        }

        if (this.hasInputHourFifthFormTarget) {
            if (this.buttonMinuteFifthFormTarget.checked) {
                this.inputMinuteFifthFormTarget.disabled = true
            } else {
                this.inputMinuteFifthFormTarget.disabled = false
            }
        }
    }

    unknownHourSixth() {
        if (this.hasInputHourSixthTarget) {
            if (this.buttonHourSixthTarget.checked) {
                this.inputHourSixthTarget.disabled = true
                this.inputMinuteSixthTarget.disabled = true
                this.buttonMinuteSixthTarget.disabled = true
                this.buttonMinuteSixthTarget.checked = true
            } else {
                this.inputHourSixthTarget.disabled = false
                this.inputMinuteSixthTarget.disabled = false
                this.buttonMinuteSixthTarget.disabled = false
                this.buttonMinuteSixthTarget.checked = false
            }
        }

        if (this.hasInputHourSixthFormTarget) {
            if (this.buttonHourSixthFormTarget.checked) {
                this.inputHourSixthFormTarget.disabled = true
                this.inputMinuteSixthFormTarget.disabled = true
                this.buttonMinuteSixthFormTarget.disabled = true
                this.buttonMinuteSixthFormTarget.checked = true
            } else {
                this.inputHourSixthFormTarget.disabled = false
                this.inputMinuteSixthFormTarget.disabled = false
                this.buttonMinuteSixthFormTarget.disabled = false
                this.buttonMinuteSixthFormTarget.checked = false
            }
        }
    }

    unknownMinuteSixth() {
        if (this.hasInputHourSixthTarget) {
            if (this.buttonMinuteSixthTarget.checked) {
                this.inputMinuteSixthTarget.disabled = true
            } else {
                this.inputMinuteSixthTarget.disabled = false
            }
        }

        if (this.hasInputHourSixthFormTarget) {
            if (this.buttonMinuteSixthFormTarget.checked) {
                this.inputMinuteSixthFormTarget.disabled = true
            } else {
                this.inputMinuteSixthFormTarget.disabled = false
            }
        }
    }

    unknownHourSeventh() {
        if (this.hasInputHourSeventhTarget) {
            if (this.buttonHourSeventhTarget.checked) {
                this.inputHourSeventhTarget.disabled = true
                this.inputMinuteSeventhTarget.disabled = true
                this.buttonMinuteSeventhTarget.disabled = true
                this.buttonMinuteSeventhTarget.checked = true
            } else {
                this.inputHourSeventhTarget.disabled = false
                this.inputMinuteSeventhTarget.disabled = false
                this.buttonMinuteSeventhTarget.disabled = false
                this.buttonMinuteSeventhTarget.checked = false
            }
        }

        if (this.hasInputHourSeventhFormTarget) {
            if (this.buttonHourSeventhFormTarget.checked) {
                this.inputHourSeventhFormTarget.disabled = true
                this.inputMinuteSeventhFormTarget.disabled = true
                this.buttonMinuteSeventhFormTarget.disabled = true
                this.buttonMinuteSeventhFormTarget.checked = true
            } else {
                this.inputHourSeventhFormTarget.disabled = false
                this.inputMinuteSeventhFormTarget.disabled = false
                this.buttonMinuteSeventhFormTarget.disabled = false
                this.buttonMinuteSeventhFormTarget.checked = false
            }
        }
    }

    unknownMinuteSeventh() {
        if (this.hasInputHourSeventhTarget) {
            if (this.buttonMinuteSeventhTarget.checked) {
                this.inputMinuteSeventhTarget.disabled = true
            } else {
                this.inputMinuteSeventhTarget.disabled = false
            }
        }

        if (this.hasInputHourSeventhFormTarget) {
            if (this.buttonMinuteSeventhFormTarget.checked) {
                this.inputMinuteSeventhFormTarget.disabled = true
            } else {
                this.inputMinuteSeventhFormTarget.disabled = false
            }
        }
    }

    unknownHourEighth() {
        if (this.hasInputHourEighthTarget) {
            if (this.buttonHourEighthTarget.checked) {
                this.inputHourEighthTarget.disabled = true
                this.inputMinuteEighthTarget.disabled = true
                this.buttonMinuteEighthTarget.disabled = true
                this.buttonMinuteEighthTarget.checked = true
            } else {
                this.inputHourEighthTarget.disabled = false
                this.inputMinuteEighthTarget.disabled = false
                this.buttonMinuteEighthTarget.disabled = false
                this.buttonMinuteEighthTarget.checked = false
            }
        }

        if (this.hasInputHourEighthFormTarget) {
            if (this.buttonHourEighthFormTarget.checked) {
                this.inputHourEighthFormTarget.disabled = true
                this.inputMinuteEighthFormTarget.disabled = true
                this.buttonMinuteEighthFormTarget.disabled = true
                this.buttonMinuteEighthFormTarget.checked = true
            } else {
                this.inputHourEighthFormTarget.disabled = false
                this.inputMinuteEighthFormTarget.disabled = false
                this.buttonMinuteEighthFormTarget.disabled = false
                this.buttonMinuteEighthFormTarget.checked = false
            }
        }
    }

    unknownMinuteEighth() {
        if (this.hasInputHourEighthTarget) {
            if (this.buttonMinuteEighthTarget.checked) {
                this.inputMinuteEighthTarget.disabled = true
            } else {
                this.inputMinuteEighthTarget.disabled = false
            }
        }

        if (this.hasInputHourEighthFormTarget) {
            if (this.buttonMinuteEighthFormTarget.checked) {
                this.inputMinuteEighthFormTarget.disabled = true
            } else {
                this.inputMinuteEighthFormTarget.disabled = false
            }
        }
    }

    editButtonEnabled() {
        if (this.editButtonTarget.click) {
            if (this.hasNameFirstTarget) {
                this.nameFirstTarget.disabled = false
                this.genderFirstTarget.disabled = false
                this.inputDateFirstTarget.disabled = false
                this.inputHourFirstTarget.disabled = false
                this.inputMinuteFirstTarget.disabled = false
                this.buttonHourFirstTarget.disabled = false
                this.buttonMinuteFirstTarget.disabled = false
            }

            if (this.hasNameSecondTarget) {
                this.nameSecondTarget.disabled = false
                this.genderSecondTarget.disabled = false
                this.inputDateSecondTarget.disabled = false
                this.inputHourSecondTarget.disabled = false
                this.inputMinuteSecondTarget.disabled = false
                this.buttonHourSecondTarget.disabled = false
                this.buttonMinuteSecondTarget.disabled = false
            }

            if (this.hasNameThirdTarget) {
                this.nameThirdTarget.disabled = false
                this.genderThirdTarget.disabled = false
                this.inputDateThirdTarget.disabled = false
                this.inputHourThirdTarget.disabled = false
                this.inputMinuteThirdTarget.disabled = false
                this.buttonHourThirdTarget.disabled = false
                this.buttonMinuteThirdTarget.disabled = false
            }

            if (this.hasNameFourthTarget) {
                this.nameFourthTarget.disabled = false
                this.genderFourthTarget.disabled = false
                this.inputDateFourthTarget.disabled = false
                this.inputHourFourthTarget.disabled = false
                this.inputMinuteFourthTarget.disabled = false
                this.buttonHourFourthTarget.disabled = false
                this.buttonMinuteFourthTarget.disabled = false
            }

            if (this.hasNameFifthTarget) {
                this.nameFifthTarget.disabled = false
                this.genderFifthTarget.disabled = false
                this.inputDateFifthTarget.disabled = false
                this.inputHourFifthTarget.disabled = false
                this.inputMinuteFifthTarget.disabled = false
                this.buttonHourFifthTarget.disabled = false
                this.buttonMinuteFifthTarget.disabled = false
            }

            if (this.hasNameSixthTarget) {
                this.nameSixthTarget.disabled = false
                this.genderSixthTarget.disabled = false
                this.inputDateSixthTarget.disabled = false
                this.inputHourSixthTarget.disabled = false
                this.inputMinuteSixthTarget.disabled = false
                this.buttonHourSixthTarget.disabled = false
                this.buttonMinuteSixthTarget.disabled = false
            }

            if (this.hasNameSeventhTarget) {
                this.nameSeventhTarget.disabled = false
                this.genderSeventhTarget.disabled = false
                this.inputDateSeventhTarget.disabled = false
                this.inputHourSeventhTarget.disabled = false
                this.inputMinuteSeventhTarget.disabled = false
                this.buttonHourSeventhTarget.disabled = false
                this.buttonMinuteSeventhTarget.disabled = false
            }

            if (this.hasNameEighthTarget) {
                this.nameEighthTarget.disabled = false
                this.genderEighthTarget.disabled = false
                this.inputDateEighthTarget.disabled = false
                this.inputHourEighthTarget.disabled = false
                this.inputMinuteEighthTarget.disabled = false
                this.buttonHourEighthTarget.disabled = false
                this.buttonMinuteEighthTarget.disabled = false
            }
        }
    }

    disableFormFirst() {
        if (this.hasCheckBoxFirstFormTarget) {
            this.nameFirstFormTarget.disabled = !this.checkBoxFirstFormTarget.checked
            this.genderFirstFormTarget.disabled = !this.checkBoxFirstFormTarget.checked
            this.inputDateFirstFormTarget.disabled = !this.checkBoxFirstFormTarget.checked
            this.inputHourFirstFormTarget.disabled = !this.checkBoxFirstFormTarget.checked
            this.inputMinuteFirstFormTarget.disabled = !this.checkBoxFirstFormTarget.checked
            this.buttonHourFirstFormTarget.checked = !this.checkBoxFirstFormTarget.checked
            this.buttonMinuteFirstFormTarget.checked = !this.checkBoxFirstFormTarget.checked
            this.buttonHourFirstFormTarget.disabled = !this.checkBoxFirstFormTarget.checked
            this.buttonMinuteFirstFormTarget.disabled = !this.checkBoxFirstFormTarget.checked
        }
    }

    disableFormSecond() {
        if (this.hasCheckBoxSecondFormTarget) {
            this.nameSecondFormTarget.disabled = !this.checkBoxSecondFormTarget.checked
            this.genderSecondFormTarget.disabled = !this.checkBoxSecondFormTarget.checked
            this.inputDateSecondFormTarget.disabled = !this.checkBoxSecondFormTarget.checked
            this.inputHourSecondFormTarget.disabled = !this.checkBoxSecondFormTarget.checked
            this.inputMinuteSecondFormTarget.disabled = !this.checkBoxSecondFormTarget.checked
            this.buttonHourSecondFormTarget.checked = !this.checkBoxSecondFormTarget.checked
            this.buttonMinuteSecondFormTarget.checked = !this.checkBoxSecondFormTarget.checked
            this.buttonHourSecondFormTarget.disabled = !this.checkBoxSecondFormTarget.checked
            this.buttonMinuteSecondFormTarget.disabled = !this.checkBoxSecondFormTarget.checked
        }
    }

    disableFormThird() {
        if (this.hasCheckBoxThirdFormTarget) {
            this.nameThirdFormTarget.disabled = !this.checkBoxThirdFormTarget.checked
            this.genderThirdFormTarget.disabled = !this.checkBoxThirdFormTarget.checked
            this.inputDateThirdFormTarget.disabled = !this.checkBoxThirdFormTarget.checked
            this.inputHourThirdFormTarget.disabled = !this.checkBoxThirdFormTarget.checked
            this.inputMinuteThirdFormTarget.disabled = !this.checkBoxThirdFormTarget.checked
            this.buttonHourThirdFormTarget.checked = !this.checkBoxThirdFormTarget.checked
            this.buttonMinuteThirdFormTarget.checked = !this.checkBoxThirdFormTarget.checked
            this.buttonHourThirdFormTarget.disabled = !this.checkBoxThirdFormTarget.checked
            this.buttonMinuteThirdFormTarget.disabled = !this.checkBoxThirdFormTarget.checked
        }
    }

    disableFormFourth() {
        if (this.hasCheckBoxFourthFormTarget) {
            this.nameFourthFormTarget.disabled = !this.checkBoxFourthFormTarget.checked
            this.genderFourthFormTarget.disabled = !this.checkBoxFourthFormTarget.checked
            this.inputDateFourthFormTarget.disabled = !this.checkBoxFourthFormTarget.checked
            this.inputHourFourthFormTarget.disabled = !this.checkBoxFourthFormTarget.checked
            this.inputMinuteFourthFormTarget.disabled = !this.checkBoxFourthFormTarget.checked
            this.buttonHourFourthFormTarget.checked = !this.checkBoxFourthFormTarget.checked
            this.buttonMinuteFourthFormTarget.checked = !this.checkBoxFourthFormTarget.checked
            this.buttonHourFourthFormTarget.disabled = !this.checkBoxFourthFormTarget.checked
            this.buttonMinuteFourthFormTarget.disabled = !this.checkBoxFourthFormTarget.checked
        }
    }

    disableFormFifth() {
        if (this.hasCheckBoxFifthFormTarget) {
            this.nameFifthFormTarget.disabled = !this.checkBoxFifthFormTarget.checked
            this.genderFifthFormTarget.disabled = !this.checkBoxFifthFormTarget.checked
            this.inputDateFifthFormTarget.disabled = !this.checkBoxFifthFormTarget.checked
            this.inputHourFifthFormTarget.disabled = !this.checkBoxFifthFormTarget.checked
            this.inputMinuteFifthFormTarget.disabled = !this.checkBoxFifthFormTarget.checked
            this.buttonHourFifthFormTarget.checked = !this.checkBoxFifthFormTarget.checked
            this.buttonMinuteFifthFormTarget.checked = !this.checkBoxFifthFormTarget.checked
            this.buttonHourFifthFormTarget.disabled = !this.checkBoxFifthFormTarget.checked
            this.buttonMinuteFifthFormTarget.disabled = !this.checkBoxFifthFormTarget.checked
        }
    }

    disableFormSixth() {
        if (this.hasCheckBoxSixthFormTarget) {
            this.nameSixthFormTarget.disabled = !this.checkBoxSixthFormTarget.checked
            this.genderSixthFormTarget.disabled = !this.checkBoxSixthFormTarget.checked
            this.inputDateSixthFormTarget.disabled = !this.checkBoxSixthFormTarget.checked
            this.inputHourSixthFormTarget.disabled = !this.checkBoxSixthFormTarget.checked
            this.inputMinuteSixthFormTarget.disabled = !this.checkBoxSixthFormTarget.checked
            this.buttonHourSixthFormTarget.checked = !this.checkBoxSixthFormTarget.checked
            this.buttonMinuteSixthFormTarget.checked = !this.checkBoxSixthFormTarget.checked
            this.buttonHourSixthFormTarget.disabled = !this.checkBoxSixthFormTarget.checked
            this.buttonMinuteSixthFormTarget.disabled = !this.checkBoxSixthFormTarget.checked
        }
    }

    disableFormSeventh() {
        if (this.hasCheckBoxSeventhFormTarget) {
            this.nameSeventhFormTarget.disabled = !this.checkBoxSeventhFormTarget.checked
            this.genderSeventhFormTarget.disabled = !this.checkBoxSeventhFormTarget.checked
            this.inputDateSeventhFormTarget.disabled = !this.checkBoxSeventhFormTarget.checked
            this.inputHourSeventhFormTarget.disabled = !this.checkBoxSeventhFormTarget.checked
            this.inputMinuteSeventhFormTarget.disabled = !this.checkBoxSeventhFormTarget.checked
            this.buttonHourSeventhFormTarget.checked = !this.checkBoxSeventhFormTarget.checked
            this.buttonMinuteSeventhFormTarget.checked = !this.checkBoxSeventhFormTarget.checked
            this.buttonHourSeventhFormTarget.disabled = !this.checkBoxSeventhFormTarget.checked
            this.buttonMinuteSeventhFormTarget.disabled = !this.checkBoxSeventhFormTarget.checked
        }
    }

    disableFormEighth() {
        if (this.hasCheckBoxEighthFormTarget) {
            this.nameEighthFormTarget.disabled = !this.checkBoxEighthFormTarget.checked
            this.genderEighthFormTarget.disabled = !this.checkBoxEighthFormTarget.checked
            this.inputDateEighthFormTarget.disabled = !this.checkBoxEighthFormTarget.checked
            this.inputHourEighthFormTarget.disabled = !this.checkBoxEighthFormTarget.checked
            this.inputMinuteEighthFormTarget.disabled = !this.checkBoxEighthFormTarget.checked
            this.buttonHourEighthFormTarget.checked = !this.checkBoxEighthFormTarget.checked
            this.buttonMinuteEighthFormTarget.checked = !this.checkBoxEighthFormTarget.checked
            this.buttonHourEighthFormTarget.disabled = !this.checkBoxEighthFormTarget.checked
            this.buttonMinuteEighthFormTarget.disabled = !this.checkBoxEighthFormTarget.checked
        }
    }
}