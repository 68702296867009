import React from "react";
import { Image, Transformer } from "react-konva";
import useImage from "use-image";

const NatalChartGrid = ({ shapeProps, isSelected, onSelect, onChange, canvasZoomValue }) => {

    function rotateRight(arr){
        let last = arr.pop();
        arr.unshift(last);
        return arr;
    }

    function rotateLeft(arr){
        let first = arr.shift();
        arr.push(first)
        return arr;
    }

    const directions = shapeProps.directions;
    const sitting = shapeProps.data['sector']['sitting'];
    const facing = shapeProps.data['sector']['facing'];
    const sitting_arrow = shapeProps.data['facingSitting']['sitting_arrow']
    const facing_arrow = shapeProps.data['facingSitting']['facing_arrow']
    const natalValueGrid = shapeProps.data['natalFS']['grid'];
    const sequence = shapeProps.data['sequence'];
    const annualOptions = shapeProps.annualOptions;
    const monthlyOptions = shapeProps.monthlyOptions;
    const annualValueGrid = shapeProps.data['annualFS']['grid'];
    const monthlyValueGrid = shapeProps.data['monthlyFS']['grid'];
    const baguaSelected = shapeProps.baguaSelected;
    const guaSymbol = shapeProps.data['guaSymbol'];
    const guaName = shapeProps.data['guaName'];
    const sideHover = shapeProps.sideHover;
    const descEnabled = shapeProps.descEnabled;
    const descGrid = shapeProps.data['desc']['grid'];
    const shortDescGrid = shapeProps.data['desc']['short_grid'];

    const baseSVG =
        `<svg height="300px" width="300px" x="0px" y="0px" viewbox="0 0 207 142" xmlns="http://www.w3.org/2000/svg" version="1.1" style="transform: translate(0,0)">
            <svg x="126" y="5">
                ${isSelected ? "<path fill-rule='evenodd' d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z'/><path d='M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z'/>" : ""}
            </svg>
            <svg x="158" y="5">
                ${isSelected ? "<path fill-rule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'/><path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z'/>" : ""}
            </svg>

            <text x="30" y="60" fill="red" font-size="18">${directions[0]}</text>
            ${facing_arrow === directions[0] ? 
                "<svg x='13' y='28' fill='red'><path fill-rule='evenodd' d='M2 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1H3.707l10.147 10.146a.5.5 0 0 1-.708.708L3 3.707V8.5a.5.5 0 0 1-1 0v-6z'/></svg>"
            : ''}"
            <text x="27" y="35" fill="red" font-size="10">${facing_arrow === directions[0] ? sequence : ''}</text>
            ${sitting_arrow === directions[0] ? 
                "<line x1='16' y1='52' x2='45' y2='30' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="63" y="85" fill="${sideHover === directions[0] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[0]}</text>
            <text x="104" y="85" fill="${sideHover === directions[0] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[0]}</text>
            <text x="80" y="110" fill="${sideHover === directions[0] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[0]}</text>
            <text x="104" y="100" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[0] : ""}</text>
            <text x="104" y="115" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[0] : ""}</text>
            <text x="75" y="100" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[0]] != false ? guaSymbol[directions[0]] : ""}</text>
            <text x="75" y="115" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[0]] != false ? guaName[directions[0]] : ""}</text>

            <text x="145" y="60" fill="red" font-size="18">${directions[1]}</text>
            ${facing_arrow === directions[1] ? 
                "<svg x='142' y='25' fill='red'><path fill-rule='evenodd' d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z'/></svg>"
            : ''}"
            <text x="157" y="37" fill="red" font-size="10">${facing_arrow === directions[1] ? sequence : ''}</text>
            ${sitting_arrow === directions[1] ? 
                "<line x1='134' y1='40' x2='170' y2='40' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="126" y="85" fill="${sideHover === directions[1] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[1]}</text>
            <text x="167" y="85" fill="${sideHover === directions[1] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[1]}</text>
            <text x="143" y="110" fill="${sideHover === directions[1] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[1]}</text>
            <text x="167" y="100" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[1] : ""}</text>
            <text x="167" y="115" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[1] : ""}</text>
            <text x="135" y="100" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[1]] != false ? guaSymbol[directions[1]] : ""}</text>
            <text x="135" y="115" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[1]] != false ? guaName[directions[1]] : ""}</text>

            <text x="250" y="60" fill="red" font-size="18">${directions[2]}</text>
            ${facing_arrow === directions[2] ? 
                "<svg x='275' y='26' fill='red'><path fill-rule='evenodd' d='M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z'/></svg>"
            : ''}"
            <text x="255" y="35" fill="red" font-size="10">${facing_arrow === directions[2] ? sequence : ''}</text>
            ${sitting_arrow === directions[2] ? 
                "<line x1='265' y1='30' x2='289' y2='52' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="189" y="85" fill="${sideHover === directions[2] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[2]}</text>
            <text x="230" y="85" fill="${sideHover === directions[2] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[2]}</text>
            <text x="206" y="110" fill="${sideHover === directions[2] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[2]}</text>
            <text x="230" y="100" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[2] : ""}</text>
            <text x="230" y="115" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[2] : ""}</text>
            <text x="200" y="100" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[2]] != false ? guaSymbol[directions[2]] : ""}</text>
            <text x="200" y="115" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[2]] != false ? guaName[directions[2]] : ""}</text>

            <text x="30" y="150" fill="red" font-size="18">${directions[7]}</text>
            ${facing_arrow === directions[7] ? 
                "<svg x='11' y='136' fill='red'><path fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'/></svg>"
            : ''}"
            <text x="11" y="160" fill="red" font-size="10">${facing_arrow === directions[7] ? sequence : ''}</text>
            ${sitting_arrow === directions[7] ? 
                "<line x1='20' y1='129' x2='20' y2='163' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="63" y="135" fill="${sideHover === directions[7] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[7]}</text>
            <text x="104" y="135" fill="${sideHover === directions[7] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[7]}</text>
            <text x="80" y="160" fill="${sideHover === directions[7] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[7]}</text>
            <text x="104" y="150" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[7] : ""}</text>
            <text x="104" y="165" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[7] : ""}</text>
            <text x="75" y="150" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[7]] != false ? guaSymbol[directions[7]] : ""}</text>
            <text x="75" y="165" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[7]] != false ? guaName[directions[7]] : ""}</text>

            <text x="126" y="135" fill="${sideHover === "center" && descEnabled != 0 ? "gray" : "red"}" font-size="16">${shapeProps.data['facingSitting']['st_fifth']}</text>
            <text x="167" y="135" fill="${sideHover === "center" && descEnabled != 0 ? "gray" : "red"}" font-size="16">${shapeProps.data['facingSitting']['fc_fifth']}</text>
            <text x="143" y="160" fill="${sideHover === "center" && descEnabled != 0 ? "gray" : "red"}" font-size="31">${shapeProps.data['natalFS']['center']}</text>
            <text x="167" y="150" fill="blue" font-size="16">${annualOptions != false ? shapeProps.data['annualFS']['center'] : ""}</text>
            <text x="167" y="165" fill="green" font-size="16">${monthlyOptions != false ? shapeProps.data['monthlyFS']['center'] : ""}</text>

            <text x="250" y="150" fill="red" font-size="18">${directions[3]}</text>
            ${facing_arrow === directions[3] ? 
                "<svg x='280' y='136' fill='red'><path fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/></svg>"
            : ''}"
            <text x="273" y="160" fill="red" font-size="10">${facing_arrow === directions[3] ? sequence : ''}</text>
            ${sitting_arrow === directions[3] ? 
                "<line x1='284' y1='129' x2='284' y2='163' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="189" y="135" fill="${sideHover === directions[3] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[3]}</text>
            <text x="230" y="135" fill="${sideHover === directions[3] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[3]}</text>
            <text x="206" y="160" fill="${sideHover === directions[3] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[3]}</text>
            <text x="230" y="150" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[3] : ""}</text>
            <text x="230" y="165" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[3] : ""}</text>
            <text x="200" y="150" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[3]] != false ? guaSymbol[directions[3]] : ""}</text>
            <text x="200" y="165" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[3]] != false ? guaName[directions[3]] : ""}</text>

            <text x="30" y="240" fill="red" font-size="18">${directions[6]}</text>
            ${facing_arrow === directions[6] ? 
                "<svg x='13' y='245' fill='red'><path fill-rule='evenodd' d='M2 13.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1H3.707L13.854 2.854a.5.5 0 0 0-.708-.708L3 12.293V7.5a.5.5 0 0 0-1 0v6z'/></svg>"
            : ''}"
            <text x="27" y="260" fill="red" font-size="10">${facing_arrow === directions[6] ? sequence : ''}</text>
            ${sitting_arrow === directions[6] ? 
                "<line x1='16' y1='235' x2='45' y2='257' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="63" y="185" fill="${sideHover === directions[6] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[6]}</text>
            <text x="104" y="185" fill="${sideHover === directions[6] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[6]}</text>
            <text x="80" y="210" fill="${sideHover === directions[6] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[6]}</text>
            <text x="104" y="200" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[6] : ""}</text>
            <text x="104" y="215" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[6] : ""}</text>
            <text x="75" y="205" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[6]] != false ? guaSymbol[directions[6]] : ""}</text>
            <text x="75" y="220" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[6]] != false ? guaName[directions[6]] : ""}</text>

            <text x="145" y="240" fill="red" font-size="18">${directions[5]}</text>
            ${facing_arrow === directions[5] ? 
                "<svg x='142' y='245' fill='red'><path fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/></svg>"
            : ''}"
            <text x="157" y="253" fill="red" font-size="10">${facing_arrow === directions[5] ? sequence : ''}</text>
            ${sitting_arrow === directions[5] ? 
                "<line x1='134' y1='247' x2='168' y2='247' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="126" y="185" fill="${sideHover === directions[5] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[5]}</text>
            <text x="167" y="185" fill="${sideHover === directions[5] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[5]}</text>
            <text x="143" y="210" fill="${sideHover === directions[5] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[5]}</text>
            <text x="167" y="200" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[5] : ""}</text>
            <text x="167" y="215" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[5] : ""}</text>
            <text x="135" y="205" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[5]] != false ? guaSymbol[directions[5]] : ""}</text>
            <text x="135" y="220" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[5]] != false ? guaName[directions[5]] : ""}</text>

            <text x="250" y="240" fill="red" font-size="18">${directions[4]}</text>
            ${facing_arrow === directions[4] ? 
                "<svg x='275' y='245' fill='red'><path fill-rule='evenodd' d='M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z'/></svg>"
            : ''}"
            <text x="255" y="260" fill="red" font-size="10">${facing_arrow === directions[4] ? sequence : ''}</text>
            ${sitting_arrow === directions[4] ? 
                "<line x1='265' y1='253' x2='289' y2='232' stroke='red' strokeWidth='2' />"
            : ''}"
            <text x="189" y="185" fill="${sideHover === directions[4] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${sitting[4]}</text>
            <text x="230" y="185" fill="${sideHover === directions[4] && descEnabled != 0 ? "gray" : "red"}" font-size="16">${facing[4]}</text>
            <text x="206" y="210" fill="${sideHover === directions[4] && descEnabled != 0 ? "gray" : "red"}" font-size="31">${natalValueGrid[4]}</text>
            <text x="230" y="200" fill="blue" font-size="16">${annualOptions != false ? annualValueGrid[4] : ""}</text>
            <text x="230" y="215" fill="green" font-size="16">${monthlyOptions != false ? monthlyValueGrid[4] : ""}</text>
            <text x="200" y="205" fill="#5e5e5e" font-size="30" class="bagua">${baguaSelected[directions[4]] != false ? guaSymbol[directions[4]] : ""}</text>
            <text x="200" y="220" fill="#5e5e5e" font-size="15" class="bagua">${baguaSelected[directions[4]] != false ? guaName[directions[4]] : ""}</text>

            <line x1="60" y1="120" x2="240" y2="120" stroke="red" strokeWidth="2" />
            <line x1="60" y1="170" x2="240" y2="170" stroke="red" strokeWidth="2" />
            <line x1="120" y1="70" x2="120" y2="220" stroke="red" strokeWidth="2" />
            <line x1="180" y1="70" x2="180" y2="220" stroke="red" strokeWidth="2" />
        </svg>`;

    const main = "data:image/svg+xml;base64," + window.btoa(unescape(encodeURIComponent(baseSVG)));
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const [image] = useImage(main);

    const rotateChart = () => {
        if (isSelected) {
            const node = shapeRef.current;
            const stage = trRef.current;
            const sideWidth = node.width() / 10;
            const sideHeight = node.height() / 10;
            const clickPointerX = stage.getStage().getPointerPosition()["x"] * canvasZoomValue;
            const clickPointerY = stage.getStage().getPointerPosition()["y"] * canvasZoomValue;
            if (clickPointerX >  node.x() + (sideWidth * 4) && clickPointerX <  node.x() + (sideWidth * 5) && clickPointerY < node.y() + sideHeight) {
                rotateLeft(directions);
                rotateLeft(facing);
                rotateLeft(sitting);
                rotateLeft(natalValueGrid);
                rotateLeft(annualValueGrid);
                rotateLeft(monthlyValueGrid);
                rotateLeft(descGrid);
            } else if (clickPointerX >  node.x() + (sideWidth * 5) && clickPointerX <  node.x() + (sideWidth * 6)  && clickPointerY < node.y() + sideHeight) {
                rotateRight(directions);
                rotateRight(facing);
                rotateRight(sitting);
                rotateRight(natalValueGrid);
                rotateRight(annualValueGrid);
                rotateRight(monthlyValueGrid);
                rotateRight(descGrid);
            }
            onChange({
                ...shapeProps,
                directions: directions,
                data: shapeProps.data
            });
        }
    }

    const descChart = () => {
        if (isSelected) {
            const node = shapeRef.current;
            const stage = trRef.current;
            const sideWidth = node.width() / 5;
            const sideHeight = node.height() / 5;
            const clickPointerX = stage.getStage().getPointerPosition()["x"] * canvasZoomValue;
            const clickPointerY = stage.getStage().getPointerPosition()["y"] * canvasZoomValue;

            const firstGrid = clickPointerX > node.x() + sideWidth && clickPointerX < node.x() + (sideWidth * 2) && clickPointerY > node.y() + sideHeight && clickPointerY < node.y() + (sideHeight * 2);
            const secondGrid = clickPointerX > node.x() + (sideWidth * 2) && clickPointerX < node.x() + (sideWidth * 3) && clickPointerY > node.y() + sideHeight && clickPointerY < node.y() + (sideHeight * 2);
            const thirdGrid = clickPointerX > node.x() + (sideWidth * 3) && clickPointerX < node.x() + (sideWidth * 4) && clickPointerY > node.y() + sideHeight && clickPointerY < node.y() + (sideHeight * 2);
            const fourthGrid = clickPointerX > node.x() + sideWidth && clickPointerX < node.x() + (sideWidth * 2) && clickPointerY > node.y() + (sideHeight * 2) && clickPointerY < node.y() + (sideHeight * 3);
            const fifthGrid = clickPointerX > node.x() + (sideWidth * 2) && clickPointerX < node.x() + (sideWidth * 3) && clickPointerY > node.y() + (sideHeight * 2) && clickPointerY < node.y() + (sideHeight * 3);
            const sixthGrid = clickPointerX > node.x() + (sideWidth * 3) && clickPointerX < node.x() + (sideWidth * 4) && clickPointerY > node.y() + (sideHeight * 2) && clickPointerY < node.y() + (sideHeight * 3);
            const seventhGrid = clickPointerX > node.x() + sideWidth && clickPointerX < node.x() + (sideWidth * 2) && clickPointerY > node.y() + (sideHeight * 3) && clickPointerY < node.y() + (sideHeight * 4);
            const eighthGrid = clickPointerX > node.x() + (sideWidth * 2) && clickPointerX < node.x() + (sideWidth * 3) && clickPointerY > node.y() + (sideHeight * 3) && clickPointerY < node.y() + (sideHeight * 4);
            const ninthGrid = clickPointerX > node.x() + (sideWidth * 3) && clickPointerX < node.x() + (sideWidth * 4) && clickPointerY > node.y() + (sideHeight * 3) && clickPointerY < node.y() + (sideHeight * 4);

            if (firstGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[0],
                        sideHover: directions[0]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[0],
                        sideHover: directions[0]
                    });
                }
            } else if (secondGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[1],
                        sideHover: directions[1]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[1],
                        sideHover: directions[1]
                    });
                }
            } else if (thirdGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[2],
                        sideHover: directions[2]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[2],
                        sideHover: directions[2]
                    });
                }
            } else if (fourthGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[7],
                        sideHover: directions[7]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[7],
                        sideHover: directions[7]
                    });
                }
            } else if (fifthGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shapeProps.data['desc']['short_center'],
                        sideHover: 'center'
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: shapeProps.data['desc']['center'],
                        sideHover: 'center'
                    });
                }
            } else if (sixthGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[3],
                        sideHover: directions[3]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[3],
                        sideHover: directions[3]
                    });
                }
            } else if (seventhGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[6],
                        sideHover: directions[6]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[6],
                        sideHover: directions[6]
                    });
                }
            } else if (eighthGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[5],
                        sideHover: directions[5]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[5],
                        sideHover: directions[5]
                    });
                }
            } else if (ninthGrid) {
                if (descEnabled == 1) {
                    onChange({
                        ...shapeProps,
                        descTarget: shortDescGrid[4],
                        sideHover: directions[4]
                    });
                } else if (descEnabled == 2) {
                    onChange({
                        ...shapeProps,
                        descTarget: descGrid[4],
                        sideHover: directions[4]
                    });
                }
            } else {
                onChange({
                    ...shapeProps,
                    descTarget: "",
                    sideHover: ""
                });
            }
        }
    }

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.setNode(shapeRef.current);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Image
                onClick={onSelect}
                onTap={onSelect}
                {...shapeProps}
                image={image}
                ref={shapeRef}
                draggable
                onDragEnd={e => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y()
                    });
                }}
                onTransformEnd={e => {
                    // transformer is changing scale
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        width: node.width() * scaleX,
                        height: node.height() * scaleY,
                        rotation: node.rotation()
                    });
                }}
                onMouseDown={rotateChart}
                onTouchStart={rotateChart}
                onMouseMove={descChart}
                onTouchEnd={descChart}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    enabledAnchors={["top-right", "top-left", "bottom-right", "bottom-left"]}
                    anchorFill="#29A9E5"
                    anchorStrokeWidth={5}
                    rotationSnaps={[ 0, 45, 90, 135, 180, 225, 270, 315, 360]}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                        return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default NatalChartGrid;