import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "inputFacing", "buttonFacing", "inputSitting", "buttonSitting" ]

    connect() {
        this.facing()
        this.sitting()
    }

    facing() {
        this.inputFacingTarget.disabled = this.buttonFacingTarget.checked
    }

    sitting() {
        this.inputSittingTarget.disabled = this.buttonSittingTarget.checked
    }
}